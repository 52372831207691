import React from "react";

import '../Styles/Wizzard.css'
import '../Styles/Header.css'

const Header = ({text,allAmount,currentAmount,logo}) => {

   allAmount = parseInt(allAmount,10);
   currentAmount = parseInt(currentAmount,10);

   //console.log(allAmount)
   var counter = 0;

   const items = [];

   for (counter = 1; counter <= allAmount; counter++){

      if(counter <= currentAmount){
         items.push(<div key={counter} className={"headerProgressBarSrc"} style={{backgroundColor:"#e5af00"}}></div>)
      }else{
         items.push(<div key={counter} className={"headerProgressBarSrc"} ></div>)
      }
   }

   //console.log(logo)

   const decodedLogo = `data:image/svg+xml;base64,${logo}`;

   //console.log(decodedLogo)

   return <div style={{textAlign:"center"}}>

      <div>
         <img src={decodedLogo} className="maxLogoSize" alt="Logo" />
      </div>
      <div>
         <div className={"row headerProgressSrc"}>
         {items}
         </div>
      </div>
      <div className={"mainYellow"}>{text}</div>
   </div>
}

export default Header