import React, { useState, useEffect, useRef } from 'react';
import MobileSelect from 'mobile-select';


function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

const SelectBox = ({ className, name, placeholder,unique, required, onChange = () => {}, checkInformation = () => {},elementData, mappedData = null, id = null, pageId= null, choiceId= null, question = null,  innerFormChange= null, linkedSelectOptions}) => {
    const [selectbox, setSelectbox] = useState('')

    const triggerRef = useRef(null);
    const mobileSelectRef = useRef(null);

    /** generate mobile scroll variant instance */
    useEffect(() => {
        if (!mobileSelectRef.current && isMobileDevice()) {
            mobileSelectRef.current = new MobileSelect({
                wheels: [{ data: transformData(options) }],
                trigger: triggerRef.current,
                triggerDisplayValue: false,
                onChange: data => handleScrollSelect(data[0]),
                ensureBtnText: "auswählen",
                cancelBtnText: "abbrechen"
            });
            console.log(elementData["selecttypes"])
        }
        if(mobileSelectRef.current) {
            return () => {
                mobileSelectRef.current?.destroy();
            };
        }

    }, []);




    const updateSelectStyle = (value) => {
        const selectElement = document.getElementById(unique);
        if (selectElement) {
            selectElement.style.color = value === "" ? '#808080' : 'black';
        }
    };

    useEffect(() => {
        if(mappedData !== null) {
            setSelectbox(mappedData);
            updateSelectStyle(mappedData);
        }
    }, [mappedData, unique]);


    const handleInputChange = (e) => {
        const newValue = e.target.value;
        //console.log('newValue');
        //console.log(newValue);
        setSelectbox(newValue);
        updateSelectStyle(newValue);
        innerFormChange(e)
        onChange(e);
        //checkInformation();
    };

    /** select handle for mobile scroll variant */
    const handleScrollSelect = (value) => {
        //console.log('handleCountrySelect')
        setSelectbox(value);

        const simulatedEvent = {
            currentTarget: {
                value: value,
                name: name,
                required: required,
                id: id,
                dataset: {
                    pageid: pageId,
                    choiceid: choiceId,
                    question: question
                },
                classList: {
                    contains: (className) => className === 'input',
                    add: () => {},
                    remove: () => {},
                    toggle: () => {}
                }
            },
            target: {
                value: value,
                name: name,
                required: required,
                id: id,
                dataset: {
                    pageid: pageId,
                    choiceid: choiceId,
                    question: question
                },
                classList: {
                    contains: (className) => className === 'input',
                    add: () => {},
                    remove: () => {},
                    toggle: () => {}
                }
            }
        };

        onChange(simulatedEvent);
        //checkInformation(simulatedEvent)

        if (typeof innerFormChange === 'function') {
            innerFormChange(simulatedEvent);
        }

    };

    const options = Array.isArray(elementData["selecttypes"])
        ? elementData["selecttypes"]
        : linkedSelectOptions;

    /** transform options for mobile scroll variant instance */
    function transformData(data) {
        return data.map(item => {
            if (Array.isArray(item)) {
                return item[0];
            } else if (typeof item === 'object' && item !== null) {
                return Object.values(item)[0];
            }
        });
    }

    //console.log(options)
    if (isMobileDevice()) {

        /** alternative mobile scroll variant */
        return (
                <div className={"input-group"} ref={triggerRef}>
                    <input
                        type="text"
                        value={selectbox}
                        className={"input modalInput "}
                        name={name}
                        id={unique}
                        key={unique}
                        placeholder={placeholder || "Bitte auswählen"}
                        required={required}
                        readOnly={true}
                    />
                    <img className="input-icon" src="data:image/svg+xml,%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg fill='%23808080' height='20px' width='20px' version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 330 330' xml:space='preserve'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'/%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Cpath id='XMLID_225_' d='M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393 c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393 s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z'/%3E%3C/g%3E%3C/svg%3E" />
                </div>
        );


    }else {
        return (
            <select id={unique} name={name} className={className} key={unique}  value={selectbox} onChange={handleInputChange} required={required} placeholder={placeholder}>
                <option id={"0"} value={""}>{placeholder || "Bitte auswählen"}</option>
                {options.map((option, i) => {
                    return (
                        <option key={i}>{option[i]}</option>
                    );
                })}
            </select>)
    }
};

export default SelectBox;