import "../Styles/Fileupload.css"

const Upload = () => {

    return (
    <input type="file" className={"fileUpload"}>
    </input>
    )

}


export default Upload