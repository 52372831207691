import React, {Component} from 'react';
import Header from "../Components/Header";
import Inputs from "../Components/Inputs";
import Button from "../Components/Buttons";
import '../Styles/Wizzard.css';
import Steps from '../Funktion/Steps';
import Appear from '../Funktion/Appear';
import Form from '../Components/Form';
import 'tippy.js/dist/tippy.css'
import {FaInfoCircle,FaEuroSign,FaAt,FaPhone } from "react-icons/fa";
import InfoModal from "../Components/InfoModal"
import {AiFillWarning} from "react-icons/all";
import sendWizardData from '../api/sendWizardData';



class Body extends Component {
    constructor(props) {
        super(props);
        this.state = {
            json : Steps(this.props.objects, "0"),
            editedJson: "",
            currentStep: "",
            nextStep: 0,
            backwards: "",
            headline: "",
            appearStates: [],
            lastAppearStates:[],
            alreadyManaged : [],
            clickedButton : "",
            answers: {},
            enable: true,
            amountOfForms: [],
            splitAmountArray : [],
            currentSplitAmountBool : [],
            formArrays : [],
            currentMaxKey : 1,
            currentKey : 1,
            modalKey : "",
            modalContent : "",
            modalOpen : false,
            userId : this.props.userId,
            payment: "",
        }

    }

    /** is invoked immediately after a component is mounted (inserted into the tree).
     * Initialization that requires DOM nodes should go here. If you need to load data
     * from a remote endpoint, this is a good place to instantiate the network request. */
    componentDidMount() {
        //console.log('check Elements Mount');
        this.CheckElements();

    }


    /** implement react.newLivecycle, is invoked immediately after
     * updating occurs but not called for the initial render.    */
    componentDidUpdate(){

        /* error output only for dev

        window.onerror = function(message, source, lineno, colno, error) {
            console.error('Ein Fehler ist aufgetreten:', error);
            console.error('Fehlermeldung:', message);
            console.error('Datei:', source);
            console.error('Zeile:', lineno);
            console.error('Spalte:', colno);
            console.error('Stack-Trace:', error.stack);
        };*/

        //console.log('componentDidUpdate')
        //console.log(Steps(this.props.objects, this.state.json.wizardNextStep,false,this.state.appearStates,"",this.state.json.wizardPageName,this.state.alreadyManaged))

        //console.log('state begin componentDidUpdate');
        //console.log(JSON.parse(JSON.stringify(this.state)));

        /** get clicked button on page */
        let buttonTarget = this.state.clickedButton ;

        /** has page modal */
        let ModalInside = document.querySelectorAll(".modal");

        if(buttonTarget === "backstep"){

            const buttonInFront = document.querySelector('.nextColor.btnSrc.btnDisabled');

            /** get all Forms that names passed to actual page form and count entrys  */
            //console.log('all Forms that names passed to actual page');
            //const regex = new RegExp(`^${this.state.json.pageForm.formName}\\d+$`);
            //const filteredKeys = this.state.amountOfForms.filter(form => regex.test(form.modalId));
            //console.log(filteredKeys);

           /** activate disabled button */
           if(buttonTarget !== null && buttonInFront !== null){
               buttonInFront.classList.remove("btnDisabled");
               buttonInFront.removeAttribute("disabled");
           }

           if(ModalInside){
               /** @todo check is this necessary */
               ModalInside.forEach((SingelModal,IndexModal) =>{
                   this.state.clickedButton = "";
               })
           }

        }

        this.checkAmountOfKeys();
        this.CheckElements();

    }

    checkAmountOfKeys(){

        let LinkedFormBool = false;
        let values;
        let resultLink
        let LinkedFormSting = this.state.json.linkedForm
        let filteredKeys
        let count

        if(LinkedFormSting !== undefined && LinkedFormSting !== ""){
           values = LinkedFormSting.split(":");
           resultLink = values[0];
           //console.log("resultLink");
           //console.log(resultLink);
           LinkedFormBool = true

        }

        if(LinkedFormBool){
            /** this is necessary for linked forms to display all items how can link.
             *  counted elemts was set to state.currentMaxKey and used in formArray.maxSteps,
             *  this generates Form for each item */

            const regex = new RegExp(`^${resultLink}\\d+$`);
            filteredKeys = this.state.amountOfForms.filter(form => regex.test(form.modalId));

            count = filteredKeys.length

            //console.log("filteredKeys LinkedFormBool")
            //console.log(filteredKeys)


        }else{
            /** this is necessary for checkElements
             *  counted elemts was set to state.currentMaxKey and used to count new created forms,
             *  this is for enabled or disabled button */

            const formName = this.state.json.pageForm.formName;

            //console.log("checkAmountOfKeys")
            //console.log(this.state.json.pageForm.formName)
            //console.log(this.state.amountOfForms);

            if (formName) {
                const regex = new RegExp(`^${formName}\\d+$`);
                //const formArray = this.state.amountOfForms;
                filteredKeys = this.state.amountOfForms.filter(form => regex.test(form.modalId));

                //console.log(filteredKeys);

                count = filteredKeys.length +1
            } else {
                filteredKeys = [];
                count = 0;
            }

            //console.log("filteredKeys")
            //console.log(filteredKeys)
            //console.log(count)

        }

        /** Each call to setState triggers componentDidUpdate(), which can result in a loop.
         * Therefore, we must check before the update
         * But we get error and can not use this.setState  */
        if (count !== this.state.currentMaxKey) {
            /** @todo check wy we can not use this.setState */
            //this.setState({ currentMaxKey: count });
            this.state.currentMaxKey = count
            console.log("this.state.currentMaxKey");
            console.log(this.state.currentMaxKey);

        }

    }

    /** function for control multi ore single form by condition, when is filled and not set to Yes / No  */
    CheckMultiformCondition = () =>{
        let check = null

        /** No conditions defined, so always return null */
        if (this.state.json.multipleForms === "" || this.state.json.multipleForms === undefined) {
            return null;
        }


        if (Array.isArray(this.state.json.multipleForms)) {
            //console.log('check multiple forms conditions');
            check = this.state.json.multipleForms.some(appearConditions => {
                const {selectionAnd, selectionOr} = appearConditions;

                /** Check whether all selectionAnd conditions are fulfilled */
                const andConditionsMet = !selectionAnd || selectionAnd.length === 0 || selectionAnd.every(condition => {
                    return this.state.answers.hasOwnProperty(condition.selection);
                });

                /** Check whether at least one selectionOr condition is fulfilled */
                const orConditionsMet = !selectionOr || selectionOr.length === 0 || selectionOr.some(condition => {
                    return this.state.answers.hasOwnProperty(condition.selection);
                });

                /** The condition is true if either all And conditions or at least one Or condition is fulfilled */
                return andConditionsMet && orConditionsMet;
            });
           //console.log(check);


        }else {
            //console.log(false);
            return false;
        }

        return check
    }


    /** check logik for modal content required, to change class and enabled or disabled button */
    CheckModalElements() {
        const Modal = document.querySelector('.modal:not([hidden]):not([style*=\'display: none\'])');

        if (Modal !== null) {
            //console.log("Check ModalElements");

            let disableButtonElement = true; // Standardmäßig ist der Button deaktiviert

            /** Prüfen, ob alle erforderlichen Select-Elemente einen Wert haben */
            const requiredSelectElement = Modal.querySelectorAll("select:not([hidden]):required");
            const allSelectsValid = Array.from(requiredSelectElement).every(e => e.value !== undefined && e.value !== "");

            /** Prüfen, ob alle erforderlichen Input- und Textarea-Elemente einen Wert haben */
            const inputElement = Modal.querySelectorAll("input:not([hidden]):required, textarea:not([hidden]):required");
            const allInputsValid = Array.from(inputElement).every(e => {

                //console.log(e.name)

                if (e.classList.contains("checkBox") && e.type !== "radio") {
                    return e.checked; // Für Checkboxen prüfen, ob sie markiert sind

                } else if (e.type === "radio") {
                    // Prüfen, ob mindestens ein Radio-Button der Gruppe ausgewählt ist

                    //console.log('ist type radio')
                    const name = e.name;
                    //console.log(Modal.querySelectorAll(`input[type="radio"][name="${name}"]:required`))
                    return Array.from(Modal.querySelectorAll(`input[type="radio"][name="${name}"]:required`)).some(radio => radio.checked);

                } else {
                    return e.value !== undefined && e.value !== ""; // Für andere Eingaben prüfen, ob sie einen Wert haben
                }
            });

            /** Aktivieren Sie den Button nur, wenn alle erforderlichen Felder gültig sind */
            disableButtonElement = !(allSelectsValid && allInputsValid);

            /** Verwalten der Modal-Buttons */
            const buttonElement = Modal.querySelectorAll("button:not([hidden])");
            buttonElement.forEach(element => {
                if (disableButtonElement) {
                    element.classList.add("btnDisabled");
                    element.setAttribute("disabled", "true");
                } else {
                    element.classList.remove("btnDisabled");
                    if (element.hasAttribute("disabled")) {
                        element.removeAttribute("disabled");
                    }
                }
            });

        }
    }

    CheckElements() {

        /** is init by mount and componentDidUpdate by button´s expected "zurück" */

        this.CheckModalElements();

        let inputElement
        let elementCount = 0;
        let disableButtonElement = false;
        let checkMultipleForm = false;  // is enough form filled ?



        const container = document.querySelector('.container');

        //console.log('this.state');
        //console.log(JSON.parse(JSON.stringify(this.state)));


        if (this.state.json.multipleForms !== undefined) {

            if (this.state.json.multipleForms === "Yes" || this.CheckMultiformCondition()) {
                console.log('this.state.json.multipleForms Yes');

                /** if linkedForm is defined and not empty string */
                if(this.state.json.linkedForm && this.state.json.linkedForm.trim().length > 0) {
                    inputElement = container.querySelectorAll('.formElement.linkedVisual');
                    elementCount = inputElement.length ?? 0;
                }else {
                    inputElement = container.querySelectorAll('.formElementUser');
                    elementCount = inputElement.length ?? 0;
                }

                /** if checkForms is defined and not empty string  */
                if (this.state.json.checkForms && this.state.json.checkForms.trim().length > 0  ) {
                    //console.log('checkForms is defined')
                    //console.log(this.state.json.checkForms)
                    //console.log("elementCount")
                    //console.log(elementCount)

                    disableButtonElement = true;

                    /** checkForms is either a number or a string that can be converted into a number */
                    if (this.state.json.checkForms !== undefined && !isNaN(+this.state.json.checkForms)) {

                        const checkFormsAsNumber = +this.state.json.checkForms;

                        if (elementCount >= checkFormsAsNumber) {
                            checkMultipleForm = true;
                            //console.log('checkMultipleForm = true')
                        }

                    }else if (this.state.json.checkForms === "all")  {
                        if (elementCount === this.state.currentMaxKey) {
                            checkMultipleForm = true;
                            //console.log('elementCount == this.state.currentMaxKey')
                        }

                    }


                }else if (this.state.currentMaxKey > 2) {
                    //console.log('checkForms is undefined')
                    //console.log(this.state.currentMaxKey)
                    checkMultipleForm = true;

                }else {
                    disableButtonElement = true;
                }

                inputElement = container.querySelectorAll("input:not([hidden]):not([style*='display: none']):not(.modalInput)");

            } else if(this.state.json.multipleForms === "No" || this.CheckMultiformCondition() === false ) {
                console.log('this.state.json.multipleForms No');
                disableButtonElement = true;
                inputElement = container.querySelectorAll("input:not([hidden])");
                //console.log(this.state.currentMaxKey);
                if (this.state.currentMaxKey > 1) {
                    checkMultipleForm = true;
                }

            } else {
                inputElement = container.querySelectorAll("input:not([hidden])");
                //console.log('this.state.json.multipleForms empty');
            }

        } else {
            //console.log('this.state.json.multipleForms undefined');
            inputElement = container.querySelectorAll("input:not([hidden])");

        }


        const selectElement = container.querySelectorAll("input[type=radio]");

        const buttonElement = document.querySelectorAll("[data-typedesc]");


        let managedName = [];

        let splitFormCheck = "";

        buttonElement.forEach((element, i) => {
            if (element.dataset.splitform === "Manually") {
                splitFormCheck = "Manually"
            }else if (element.dataset.splitform === "noCheck") {
                splitFormCheck = "noCheck"
            }else{
                splitFormCheck = false
            }
        })

        /** check if input type radio select is shown (wizard questions) */
        if (selectElement.length !== 0) {
            //console.log('input type radio select shown');
            selectElement.forEach((select, keyValue) => {

                const currentSelectBox = {
                    selectBoxName: select.name,
                    selectedValue: true
                }
                if (select.checked) {
                    /** combine array currentSelectBox to managedName */
                    //console.log('currentSelectBox')
                    //console.log(currentSelectBox)
                    managedName.push(currentSelectBox);

                }
            })
        }

        /** check if radio select was selected to set buttons */
        selectElement.forEach((select, keyValue) => {
            if (managedName.some(value => value.selectBoxName === select.name)) {
                // possible action wenn radio select was selected
            } else {
                disableButtonElement = true;
                //console.log('disable button, select radio is not selected');

            }

        })


        let currentSplitAmountBoolObj = this.state.currentSplitAmountBool.find(obj => obj.linkedForm === this.state.json.linkedForm);
        let currentSplitAmountBool = currentSplitAmountBoolObj ? currentSplitAmountBoolObj.splitBool : false;

        //console.log(currentSplitAmountBool);
        //console.log('splitFormCheck');
        //console.log(splitFormCheck);

        //console.log('checkMultipleForm');
        //console.log(checkMultipleForm);


            if (!splitFormCheck && inputElement.length === 0 && checkMultipleForm) {
                //console.log('singel form checked');
                disableButtonElement = false;

            }else if (!splitFormCheck && inputElement.length === 0 && currentSplitAmountBool) {
                //console.log('inputElement.length === 0 && this.state.currentSplitAmountBool');
                disableButtonElement = false;

            }else if (!splitFormCheck) {



                /** check if required select have value, to set buttons */
                const requiredSelectElement = container.querySelectorAll("select:not([hidden]):required");
                //console.log(requiredSelectElement)
                requiredSelectElement.forEach((e, index) => {
                    if (e.value === undefined || e.value === "" ) {
                        disableButtonElement = true;

                    }
                })

                /** check if all required inputs have value, to set buttons */
                const requiredInputElement = container.querySelectorAll("input:not([hidden]):not(.modalInput):required, textarea:not([hidden]):not(.modalInput):required, input.checkPayment[type=\"hidden\"]");

                requiredInputElement.forEach((e, index) => {
                    console.log('inputElement.forEach required');
                    console.log(e.name);

                    if (e.value === undefined || e.value === "" || e.classList.contains("checkBox")) {

                        if (e.classList.contains("checkBox")) {
                            console.log('contains("checkBox")');
                            if (!e.checked) {
                                disableButtonElement = true;
                                console.log('disableButtonElement !checked');
                            }
                        } else {
                            disableButtonElement = true;
                            console.log('disableButtonElement else');
                        }

                    }
                })



            }else if (splitFormCheck === "noCheck"){
                disableButtonElement = false

            }else if (splitFormCheck === "Manually"){

                if (currentSplitAmountBool) {
                    console.log('splitFormCheck currentSplitAmountBool true ');
                    disableButtonElement = false;

                }else {
                    console.log('splitFormCheck currentSplitAmountBool false ');
                    disableButtonElement = true

                }

            }



        /** main switch for buttons, to set or remove class btnDisabled
         *  the class manage the color of button */

        buttonElement.forEach((element, i) => {
            if ((element.dataset.control === "nextstep" || element.dataset.control === "safe" || element.dataset.control === "endsafe") && disableButtonElement === true) {
                element.classList.add("btnDisabled");
                //console.log('add("btnDisabled"');
                //console.log(element);
                //console.log('inputElement');
                //console.log(inputElement);

            } else {
                element.classList.remove("btnDisabled");
                //console.log('zurück remove("btnDisabled"');
                //console.log(element);
            }
        });


        /** main switch for buttons, to set or remove element attribute "disabled"
         *  by having class btnDisabled
         *  this attribute managed the button event. the class manage the color of button */

        buttonElement.forEach((element, i) => {
            if (element.dataset.control === "nextstep" || element.dataset.control === "safe" || element.dataset.control === "endsafe") {
                if (element.classList.contains("btnDisabled")) {
                    element.setAttribute("disabled", "true");
                    //console.log('setAttribute("btnDisabled"');
                } else {
                    if (element.hasAttribute("disabled")) {
                        element.removeAttribute("disabled");
                        //console.log('removeAttribute("btnDisabled"');
                    }
                }
            }
        });



        /** check regex for input´s by each key press to un set warning missing information */
        if (inputElement) {
            inputElement.forEach((element, index) => {

                /** did exist regex class to validate values ? */
                let regClassExists = checkRegXClass(element);
                let regClass;
                let validation = false;

                /** regex validation  */
                if(regClassExists){
                    element.classList.forEach((e, i ) =>{
                        if(e.includes("Reg")){
                            regClass = e;
                        }
                    })
                    validation = validateData(element, regClass)
                    //console.log('regex input element')
                    //console.log(validation)

                    if(validation) {

                        let parentElement = element.parentElement;

                        let warning = parentElement.querySelector(".warning");

                        if(warning === null){
                            warning = parentElement.querySelector(".modal_warning");
                        }

                        //let checkMark = parentElement.querySelector(".checkmark")
                        let helperText = parentElement.querySelector(".helperText");
                        let warningText = parentElement.querySelector(".warningMessage");

                        /*if(checkMark != null){
                            checkMark.classList.remove("missingInformation");

                        }*/

                        element.classList.remove("missingInformation");

                        if (warningText !== null) {
                            //console.log('warningText');
                            //console.log(warningText);
                            warningText.style.display = "none";
                            warningText.style.marginTop = "0px";
                        }

                        if (helperText !== null) {
                            helperText.style.display = "block";
                        }

                        if (warning !== null) {
                            warning.classList.remove("missingInformation");
                        }
                    }
                }


            })

        }

        console.log('this.state after checkElement');
        console.log(JSON.parse(JSON.stringify(this.state)));
        //console.log(this.state);


    }

    handleSafeClick = (modalId,userArray,question,linkedModalId) => {

        const { amountOfForms, currentMaxKey } = this.state;

        let formName = null;
        let targetID = null;
        let addUnique = false;
        //let formID = null;

        //console.log('userArray');
        //console.log(userArray);
        //console.log('amountOfForms');
        //console.log(amountOfForms);
        //console.log('modalId');
        //console.log(modalId);

        const existingForms = amountOfForms.filter(obj => obj.modalId === modalId) ;

        /** property description of userArray is special, we can use it for linked multi select box options.
         * Therefore, it must be unique, and we have to add a number  */
        const descriptionToCheck = userArray.description;

        if(existingForms.length > 0) {
            /** if we update an existing form */
            const firstMatch = existingForms[0];
            if(firstMatch.array.description) {
                /** if the form contains special value description */
                let cleanDescription = undefined;
                let setDescription = undefined;

                const settedValue = firstMatch.array.description
                const cleanValue = settedValue.match(/([a-zA-Z]+)(?:\s+\d+)?/);

                if (cleanValue && cleanValue.length === 2) {
                    cleanDescription = cleanValue[1]
                    setDescription = cleanValue[0]
                }

                if(cleanDescription && cleanDescription !== descriptionToCheck) {
                    /** when the description was changed, perform add unique number  */
                    //console.log(firstMatch.array.description)
                    //console.log(descriptionToCheck)
                    addUnique = true
                }else if(setDescription){
                    /** when the description not changed, we set the old with additional unique number  */
                    userArray.description = setDescription;
                }

            }

        } else {
            /** if we save a new form, contains special value description */
            if(descriptionToCheck !== undefined) {
                /** we must perform add unique number  */
                addUnique = true
            }
        }

        /** split modalId e.g. heritage2 with regex to string and number */
        const matches = modalId.match(/([a-zA-Z]+)(\d+)/);
        if (matches && matches.length === 3) {
            formName = matches[1];
            targetID = parseInt(matches[2], 10);
        }

        /** add unique number to special value description  */
        if(addUnique){

            /** get all forms from amountOfForms how matched to formName */
            const filteredForms = amountOfForms.filter(obj => {
                if (obj.hasOwnProperty("modalId")) {
                    const regex = new RegExp(`${formName}(\\d+)`);
                    return regex.test(obj.modalId);
                }
                return false;
            });

            //console.log('filteredForms')
            //console.log(filteredForms)

            /** get all property description from filteredForms */
            const descriptions = filteredForms.map(obj => obj.array.description);

            //console.log('descriptions')
            //console.log(descriptions)

            /** get all description how passed to descriptionToCheck and count */
            const count = descriptions.filter(description => {
                const regexPattern = new RegExp(`^${descriptionToCheck}\\s*\\d*$`);
                return regexPattern.test(description);
            }).length;

            /** if exist description multiple, add counter number */
            if (count > 0) {
                userArray.description = `${descriptionToCheck} ${count + 1}`;
            }
        }

        /** split targetString and get last array element */
        //let idAsInt = modalId.split(this.state.json.pageForm.formName).pop()
        //let targetID = parseInt(idAsInt, 10);

        let maxKeys = currentMaxKey;

        /** increase max keys if is multiForm and not linkedForm */
        if((this.state.json.multipleForms === "Yes" || this.CheckMultiformCondition()) && (this.state.json.linkedForm === undefined || this.state.json.linkedForm === "" )){
            if (targetID + 1 === currentMaxKey) {
                //console.log('increase max keys');
                maxKeys = currentMaxKey + 1
            }
            //console.log('maxKeys');
            //console.log(maxKeys);
        }



        this.setState((prevState) => {
            const updatedAmountOfForms = [...prevState.amountOfForms];

            const existingIndex = updatedAmountOfForms.findIndex((item) => item.modalId === modalId);

            const answerKey = this.state.json.pageForm.formValue || "";

            if (existingIndex !== -1) {

                updatedAmountOfForms[existingIndex] = { modalId, array: userArray, answerKey, question, linkedModalId };
            } else {

                updatedAmountOfForms.push({ modalId, array: userArray, answerKey, question, linkedModalId });
            }

            return {
                amountOfForms: updatedAmountOfForms,
                currentMaxKey: maxKeys,
                modalOpen: false
            };
        });

    }

    handleDeleteClick = (modalId) => {

        console.log('clean modal');
        console.log(modalId);

        const cleanModalId = modalId.replace(/\d+$/, ''); // delete following numbers


        this.setState((prevState) => {
            const updatedAmountOfForms = prevState.amountOfForms.filter(
                (form) => form.modalId !== modalId
            );

            const maxKeys = prevState.currentMaxKey-1

            //console.log('cleanModalId')
            //console.log(cleanModalId)

            //console.log('prevState.currentSplitAmountBool')
            //console.log(prevState.currentSplitAmountBool)

            //console.log('prevState.updatedSplitAmountArray')
            //console.log(prevState.updatedSplitAmountArray)


            const updatedCurrentSplitAmountBool = { ...prevState.currentSplitAmountBool };

            const updatedSplitAmountArray = { ...prevState.splitAmountArray };


            if (updatedCurrentSplitAmountBool.hasOwnProperty(cleanModalId)) {
                delete updatedCurrentSplitAmountBool[cleanModalId];

                if (updatedSplitAmountArray.hasOwnProperty(cleanModalId)) {
                    console.log('updatedSplitAmountArray has entry')


                    const entry = updatedSplitAmountArray[cleanModalId];
                    if (entry.hasOwnProperty(modalId)) {
                        console.log('updatedSplitAmountArray has property')
                        delete entry[modalId];
                        if (Object.keys(entry).length === 0) {

                            delete updatedSplitAmountArray[cleanModalId];
                            console.log('delete all')
                        }
                    }
                }

                console.log('indexToRemove')
                console.log(cleanModalId)
                return {
                    amountOfForms: updatedAmountOfForms,
                    currentSplitAmountBool: updatedCurrentSplitAmountBool,
                    splitAmountArray: updatedSplitAmountArray,
                    currentMaxKey: maxKeys,
                    modalOpen: false,
                };


            }else {
                return {
                    amountOfForms: updatedAmountOfForms,
                    currentMaxKey: maxKeys,
                    modalOpen: false
                };
            }


        });


        console.log("this.state.amountOfForms deleted");
        console.log(this.state.amountOfForms);

    }


    render(){
        // console.log(this.props.objects);

        let x = ""
        let appear = ""
        let alreadyManaged = this.state.alreadyManaged;
        let currentKey = this.state.currentKey;
        let maxKeys = this.state.currentMaxKey;
        let modalContent = "";
        let clickButton = this.state.clickedButton;
        //let splitAmountArray = this.state.splitAmountArray;

        let convertedArray = [];

        this.state.amountOfForms.forEach((form) => {
            const { modalId, array } = form;
            convertedArray[modalId] = {array: array};
        });

        let formArray = convertedArray;
        //console.log('new formArray');
        //console.log(formArray);

        /*const onModalCheckInformationFunction = () => {
            //console.log("callback checkModalInformation")
            this.CheckModalElements();

        }*/


        const sendWizard = () => {

            let jsonApiData = {
                "userId" : this.state.userId,
                "wizardId" : this.props.wizardId,
                "answers" : this.state.answers,
                "forms" : this.state.amountOfForms,
                "splitData" : this.state.splitAmountArray,
                "paymentData" : this.state.payment,

            };

            sendWizardData(jsonApiData)
        }


        /** logic for click event wizard buttons "weiter" "zurück" "Abschicken"  */
        const onClick = (e) => {

            /** removes the focus from the currently focused element to close the keyboard on mobile devices */
            document.activeElement.blur();

            let buttonTarget = e.currentTarget;

            /** disable event, because mobile divices do not operate with disabled attribute from Buttons */
            if (buttonTarget.disabled) {
                return;
            }

            let currentState = checkState(undefined,buttonTarget.dataset.control);

            if(currentState === false){

                if(buttonTarget.dataset.control === "nextstep" || buttonTarget.dataset.control === "safe" || buttonTarget.dataset.control === "endsafe"){

                    if(buttonTarget.dataset.control === "safe"){
                        /** send data answers to api */
                        sendWizard();

                    }

                    if(buttonTarget.dataset.control === "endsafe"){
                        /** send data answers to api */
                        sendWizard();

                        const buttonElement = document.querySelectorAll("[data-typedesc]");

                        buttonElement.forEach((element, i) => {
                            element.classList.add("btnDisabled");
                            element.setAttribute("disabled", "true");
                            });
                        return;

                    }

                    //console.log('alreadyManaged')
                    //console.log(alreadyManaged)
                    //console.log("FormArray");
                    //console.log(formArray);

                    currentKey = 1;

                    let CheckSteps = Steps(this.props.objects, buttonTarget.dataset.step,false,this.state.appearStates,buttonTarget.dataset.jump,this.state.json.wizardPageName,this.state.alreadyManaged)
                    //console.log("CheckSteps");

                    if(CheckSteps !== "") {

                        // is thiss unessesary
                        //this.setState({currentKey: currentKey})

                        /** check if the question page has already been edited, then inserted into array */
                        if (this.state.alreadyManaged.length === 0) {

                            /** get the last array element alreadyManaged, (...) Spread operator,
                             * to copy all properties of the last element in alreadyManaged and
                             * paste them into a new object . */
                            alreadyManaged[this.state.alreadyManaged.length] = {
                                ...alreadyManaged[this.state.appearStates.length],
                                key: 0,
                                done: this.state.json.wizardPageName
                            }

                        } else {

                            this.state.alreadyManaged.forEach((alreadyManagedState, i) => {
                                //console.log(alreadyManagedState);
                                if (this.state.alreadyManaged.length === i + 1) {
                                    /** check if the question page has already been edited is inserted in array */
                                    let keys = Object.keys(alreadyManaged);
                                    let indexKeys = 0;
                                    let skip = false

                                    for (indexKeys; indexKeys < keys.length; indexKeys++) {
                                        if(alreadyManaged[keys[indexKeys-1]] !== undefined){
                                            //console.log(alreadyManaged[keys[indexKeys-1]]["done"])
                                        }
                                        if (alreadyManaged[keys[indexKeys]]["done"] === this.state.json.wizardPageName) {
                                            skip = true;
                                        }
                                    }
                                    if (!skip) {
                                        alreadyManaged[this.state.alreadyManaged.length] = {
                                            ...alreadyManaged[this.state.appearStates.length],
                                            key: i,
                                            done: this.state.json.wizardPageName
                                        }


                                    }
                                }
                            })
                        }


                        const StepInit = Steps(this.props.objects, buttonTarget.dataset.step,false, this.state.appearStates, buttonTarget.dataset.jump, this.state.json.wizardPageName, this.state.alreadyManaged)

                        console.log("StepInit weiter")
                        console.log(StepInit);

                        let LinkedStepAmount = 1;
                        if(StepInit.linkedForm !== undefined && StepInit.linkedForm !== "" ){
                            let LinkedFormSting = StepInit.linkedForm
                            let values = LinkedFormSting.split(":");
                            let resultLink = values[0];

                            const regex = new RegExp(`^${resultLink}\\d+$`);
                            let filteredKeys = this.state.amountOfForms.filter(form => regex.test(form.modalId));

                            LinkedStepAmount = filteredKeys.length;
                        }else{

                            let formNameString = StepInit.pageForm.formName

                            console.log("formNameString")
                            console.log(formNameString);

                            const regex = new RegExp(`^${formNameString}\\d+$`);
                            let filteredKeys = this.state.amountOfForms.filter(form => regex.test(form.modalId));

                            LinkedStepAmount = filteredKeys.length;
                            if(LinkedStepAmount < 1){
                                LinkedStepAmount = 1;
                            }else{
                                LinkedStepAmount = LinkedStepAmount+1;
                            }

                            console.log(LinkedStepAmount);

                        }

                        clickButton = "nextstep";

                        this.setState({
                            currentMaxKey : LinkedStepAmount,
                            clickedButton : clickButton,
                            alreadyManaged: alreadyManaged,
                            subTitle: buttonTarget.dataset.step,
                            json: StepInit,
                            headline: this.state.json.pageHeadline
                        })


                    }

                }else if(buttonTarget.dataset.control === "backstep"){
                   //console.log("button zurück")
                   //console.log(this.state.json.wizardPrevStep)
                    currentKey = 1;

                    let lastKey = null;
                    let lastKeyDelete = false
                    let CheckSteps = Steps(this.props.objects, buttonTarget.dataset.step,true,this.state.appearStates,"",this.state.json.wizardPageName,this.state.alreadyManaged)
                    if(CheckSteps !== "") {

                        this.state.alreadyManaged.forEach((alreadyManagedState, i) => {

                            let ElementObject = alreadyManaged[alreadyManaged.length-1];
                            //console.log("ELementObject");
                            // console.log(ElementObject);

                            if(ElementObject !== undefined){
                                if(!lastKeyDelete){
                                    lastKey = ElementObject.done
                                    console.log("lastKey ElementObject.done");
                                    console.log(lastKey);
                                    delete alreadyManaged[alreadyManaged.length-1];
                                    lastKeyDelete = true;
                                }
                            }

                        })

                        if(lastKey === null){
                            lastKey = buttonTarget.dataset.step;
                        }

                        let alreadyMangedStep = alreadyManaged.flat(1);

                        const StepInit = Steps(this.props.objects, lastKey,true,this.state.appearStates,"",alreadyMangedStep,alreadyManaged)
                        console.log("StepInit zurück")
                        console.log(StepInit);

                        let LinkedStepAmount = 1;
                        if(StepInit.linkedForm !== undefined && StepInit.linkedForm !== "" ){
                            let LinkedFormSting = StepInit.linkedForm
                            let values = LinkedFormSting.split(":");
                            let resultLink = values[0];

                            const regex = new RegExp(`^${resultLink}\\d+$`);

                            let filteredKeys = this.state.amountOfForms.filter(form => regex.test(form.modalId));
                            LinkedStepAmount = filteredKeys.length;
                        }else{
                            let formNameString = StepInit.pageForm.formName

                            console.log("formNameString")
                            console.log(formNameString);
                            const regex = new RegExp(`^${formNameString}\\d+$`);

                            let filteredKeys = this.state.amountOfForms.filter(form => regex.test(form.modalId));
                            LinkedStepAmount = filteredKeys.length;
                            if(LinkedStepAmount < 1){
                                LinkedStepAmount = 1;
                            }else{
                                LinkedStepAmount = LinkedStepAmount+1;
                            }
                            console.log(LinkedStepAmount);
                        }


                        clickButton = "backstep";

                        this.setState({
                            currentMaxKey : LinkedStepAmount,
                            clickedButton : clickButton,
                            alreadyManaged: alreadyManaged.flat(1),
                            subTitle: buttonTarget.dataset.step,
                            json: StepInit,
                            headline: this.state.json.pageHeadline
                        })

                    }

                }

            }
        }

        /** event listener info icon open */
        const onClickInfoIcon =(e) =>{

            try {
                if (this.state.modalOpen === false) {

                    this.setState({modalOpen: true})
                    let id = e.currentTarget.id;

                    let modalId = document.querySelectorAll(".infoModal")
                    //console.log("modalId")
                    //console.log(modalId)

                    let currentModal;

                    modalId.forEach((currentObj, i) => {
                        if (id === currentObj.id) {
                            currentModal = currentObj;
                        }
                    })

                    /** @todo is this necessary  */
                    let parentNode = currentModal.parentNode;

                    if (parentNode.id !== "Placeholder") {
                        if(parentNode.contains(currentModal)){
                           // console.log("yesDoes")
                            parentNode.removeChild(currentModal);
                        }else{
                           // console.log("noDoesnt")
                        }

                    }


                    currentModal.style.display = "block";
                    let element = document.querySelector("#Placeholder")
                    element.appendChild(currentModal);

                    let currentElementPlaceholder = document.querySelector("#div"+currentModal.id);

                    const backGroundBody = document.querySelector(".border");
                    const backGroundHeader = document.querySelector(".headerContent")

                    backGroundHeader.classList.add("backGroundBrightnessInfo");
                    backGroundBody.classList.add("backGroundBrightnessInfo");


                    /** add event listener to infomodal button close */
                    const closeBtn = currentModal.querySelector(".infoModalDoneButton")

                    closeBtn.addEventListener("click", () => {
                        backGroundBody.classList.remove("backGroundBrightnessInfo")
                        backGroundHeader.classList.remove("backGroundBrightnessInfo");
                        currentModal.style.display = "none";
                        currentElementPlaceholder.appendChild(currentModal);

                        this.setState({modalOpen: false})
                    })
                }
            }catch (e) {
                console.log(e)
            }
        }


        /** define callbackfunktion for form  */
        const onChangeValidation = (e) => {

            //console.log('callbackfunktion for form')

            if(e === undefined){
                console.log('element === undefined')
                return;
            }

            let currentElement = e.currentTarget;

            //console.log('currentElement')
            //console.log(currentElement)

            let ClassExist = checkRegXClass(currentElement);
            let regClass;

            if(ClassExist){
                e.currentTarget.classList.forEach((elem, i ) =>{
                    if(elem.includes("Reg")){
                        regClass = elem;
                    }
                })
                validateData(e.currentTarget, regClass)

            }
        }


        /** callback function button to create or open user form */
        const onClickForm = (e) =>{

            //console.log("onClickForm modal")
            //console.log(e.currentTarget);
            //console.log(modalContent);

            /** if form is current not open */
            if(this.state.modalOpen === false) {

                /** set states to open form  */
                /** @todo check combine setstate */
                this.setState({modalOpen: true})
                this.setState({modalKey: e.currentTarget.id})
                this.setState({modalContent: modalContent})

                /** get id from current modal by combine current target id and formName*/
                let wizardPage = this.state.json.pageForm.formName
                let currentModal;
                let id = e.currentTarget.id;
                let finalId = wizardPage + id
                //console.log('finalId');
                //console.log(finalId);

                /** search and get current modal from DOM */
                const modal = document.querySelectorAll(".modal")
                modal.forEach((currentObj, i) => {

                    if (finalId === currentObj.id) {
                        currentModal = currentObj;
                        // console.log('currentObj');
                        // console.log(currentObj);
                    }
                })

                /** if exist current modal, append to modalDiv in buttonContent  */
                let element = document.querySelector(".modalDiv")

                /** @todo what is this check function  */
                if (currentModal !== undefined) {
                    element.appendChild(currentModal)
                } else {
                    return
                }

                /** search elements to modifier */
                const closeBtn = currentModal.querySelector(".modalDoneButton, .close")
                const deleteBtn = currentModal.querySelector(".modalDeleteBtn");
                const saveBtn = currentModal.querySelector(".modalDoneButton")
                const backGroundBody = document.querySelector(".border");
                const backGroundHeader = document.querySelector(".headerContent")

                /** change stile from current modal to show */
                currentModal.style.display = "block";

                /** change style from background to gray out  */
                backGroundHeader.classList.add("backGroundBrightnessModal");
                backGroundBody.classList.add("backGroundBrightnessModal");

                /** create event listener to close modal */
                closeBtn.addEventListener("click", () => {
                    backGroundBody.classList.remove("backGroundBrightnessModal");
                    backGroundHeader.classList.remove("backGroundBrightnessModal");
                    currentModal.style.display = "none";

                    this.setState({modalOpen: false})
                })

                /** create event listener to save modal */
                saveBtn.addEventListener("click", () => {

                    let checkInputs = checkState("Modal");

                    if (checkInputs !== true) {
                        const question = currentModal.dataset.question || "";
                        const linkedModalId = currentModal.dataset.linked || "";

                        const userArray = saveUserData(currentModal.id, currentModal);

                        this.handleSafeClick(currentModal.id,userArray,question,linkedModalId);

                        backGroundBody.classList.remove("backGroundBrightnessModal");
                        backGroundHeader.classList.remove("backGroundBrightnessModal");
                        currentModal.style.display = "none"

                    }

                })

                /** if exist delete button add event listener */
                if (deleteBtn !== null && deleteBtn !== undefined) {
                    deleteBtn.addEventListener('click', () => {
                        this.handleDeleteClick(currentModal.id);
                        backGroundBody.classList.remove("backGroundBrightnessModal");
                        backGroundHeader.classList.remove("backGroundBrightnessModal");
                        currentModal.style.display = "none"

                    });

                }

            }

        }


        /** collect all elements inside current modal and create object inputArray */
        function saveUserData(id = null,documentModal) {
            let userData = documentModal.querySelector(".modal_innerConntent")
            let userInput = userData.querySelectorAll("input,select,textarea")
            let inputArray = {};

            userInput.forEach((input,i) => {

                console.log('save element '+input.name);
                console.log(input.value);

                if(input.value) {
                    if (input.type === "radio") {
                        if (input.checked) {
                            inputArray[input.name] = input.value;
                        }
                    } else {
                        inputArray[input.name] = input.value;
                    }
                }


            });

          return inputArray

        }


        let appearState = this.state.appearStates
        let selectedAnwsers = this.state.answers


        /** on change event for all elements outside modal */
        const onChangeInputs = (e) =>{
            console.log('onChangeInputs');
            let state;
            let stateKey;
            const currentQuestion = e.currentTarget.dataset.question || "";

            /** generate state and state key string, from current element, like 0:0:0  */
            if(e.currentTarget.dataset.questionid !== undefined){
                state = e.currentTarget.dataset.pageid+":"+e.currentTarget.dataset.questionid+":"+e.currentTarget.dataset.choiceid;
                stateKey = e.currentTarget.dataset.pageid+":"+e.currentTarget.dataset.questionid;

            }else{
                /** @todo check witch elemet has no dataset.questionid   */
                console.log('questionid undefined');
                state = e.currentTarget.dataset.pageid+":"+e.currentTarget.id+":"+e.currentTarget.dataset.choiceid;
                stateKey = e.currentTarget.dataset.pageid+":"+e.currentTarget.id;

            }

            const targetType = e.currentTarget.type;
            const regClassExists = checkRegXClass(e.currentTarget);
            const currentName = e.currentTarget.name

            let currentAnswer = e.currentTarget.value
            if(currentAnswer === "on"){
                if(e.currentTarget.nextSibling.innerText !== undefined){
                    currentAnswer = e.currentTarget.nextSibling.innerText;
                }
            }

            let regClass;
            if(regClassExists){
                e.currentTarget.classList.forEach((elem, i ) =>{
                    if(elem.includes("Reg")){
                        regClass = elem;
                    }
                })

                validateData(e.currentTarget, regClass)
            }



            //console.log(currentAnswer);
            //console.log(state);
            //console.log(stateKey);
            //console.log(currentQuestion);
            //console.log(currentAnswer);
            //console.log(currentName);
            //console.log(e.currentTarget);

            let statusChange = false;
            let deleteKey;

            if(this.state.appearStates.length === 0){
                appearState[this.state.appearStates.length] = {...appearState[stateKey],key:stateKey,selection:state}
                selectedAnwsers[state] = {...selectedAnwsers[stateKey], answer : currentAnswer, nameInput: currentName, question: currentQuestion }

            }else{
                this.state.appearStates.forEach((appearStatus,i)=>{

                    if(appearStatus.key === stateKey){

                        selectedAnwsers[state] = {...selectedAnwsers[stateKey], answer : currentAnswer, nameInput: currentName, question: currentQuestion }
                        deleteKeyFromObjectAnswers(selectedAnwsers,stateKey,state,targetType);

                        if(targetType === "radio"){
                            deleteKey = (this.state.appearStates.length-1)

                            //Änderung der Daten um Array bis zum Gewählten Element

                            this.state.appearStates.splice(i+1,deleteKey)

                            //this.state.answers.splice(i+1, deleteKey)
                            // Anpassung Array zu Objekt 'answers'
                            const keysToRemove = Object.keys(this.state.answers).slice(i + 1, deleteKey + 1);
                            keysToRemove.forEach(key => {
                                delete this.state.answers[key];
                            });

                            appearStatus.selection = state

                            statusChange = true;
                            // console.log(appearStatus);
                        }

                      //  appearState[i] = {...appearState[stateKey],key:appearStatus.key,stateStatus:appearStatus.stateStatus}
                    }else if(this.state.appearStates.length === i+1 && statusChange === false){

                        appearState[this.state.appearStates.length] = {...appearState[stateKey],key:stateKey,selection:state}
                        selectedAnwsers[state] = {...selectedAnwsers[stateKey], answer : currentAnswer , nameInput: currentName, question: currentQuestion}
                        deleteKeyFromObjectAnswers(selectedAnwsers,stateKey,state);
                    }
                })


            }

            /** @todo check combine setstate  */
            this.setState({appearState: appearState })
            this.setState({lastAppearStates: Appear(this.state.json.pageQuestion,this.state.appearStates)})
            this.setState({enable: true})

        //    console.log("AppearState");
        //    console.log(appearState)
        //    console.log("SelectedAnswers")
        //    console.log(selectedAnwsers);

        }

        const onPaymentSuccess = (paymentDetails) => {

            this.setState({payment: paymentDetails});
            //console.log("Zahlung erfolgreich:", paymentDetails);

            /** unset goback button */
            const backButtonElement = document.querySelector("[data-control='backstep']");
            //backButtonElement.classList.add("btnDisabled");
            backButtonElement.setAttribute("disabled", "true");
            backButtonElement.remove();

            /** always send data when payment is success */
            sendWizard();

        };

        const onChangeSplitAmount = (e,elementQuestion) => {

            /** callback event splitbox returns array of split elements*/
            //console.log("OnchangeSPlitAmount")
            //console.log(e);

            let maxAmount = parseInt(this.state.json.maxAmount);
            let userAmounts = 0;
            let formValue = this.state.json.formVariable


            /** calculate sum of all split */
             Object.values(e).forEach(valueUser => {
                userAmounts = userAmounts + valueUser;
            });

            const linkedForm = this.state.json.linkedForm;
            const splitArray = e;
            const answerKey = formValue;
            const question = elementQuestion || "";

            if(userAmounts === maxAmount) {

                const splitBool = true;

                /** split is complete */

                this.setState( (prevState) => {

                    const updatedSplitAmountArray = [...prevState.splitAmountArray];
                    const existingSplitAmountArray = updatedSplitAmountArray.findIndex((item) => item.linkedForm = linkedForm)

                    if(existingSplitAmountArray !== -1) {
                        updatedSplitAmountArray[existingSplitAmountArray] = { linkedForm, splitArray, answerKey, question }
                    } else {
                        updatedSplitAmountArray.push({ linkedForm, splitArray, answerKey, question });
                    }

                    const updatedSplitAmountBool = [...prevState.currentSplitAmountBool];
                    const existingSplitAmountBool = updatedSplitAmountBool.findIndex((item) => item.linkedForm = linkedForm)

                    if(existingSplitAmountBool !== -1) {
                        updatedSplitAmountBool[existingSplitAmountBool] = { linkedForm, splitBool }
                    } else {
                        updatedSplitAmountBool.push({ linkedForm, splitBool });
                    }

                    return {
                        splitAmountArray: updatedSplitAmountArray,
                        currentSplitAmountBool: updatedSplitAmountBool
                    }


                });


            }else{

                const splitBool = false;

                this.setState( (prevState) => {

                    const updatedSplitAmountBool = [...prevState.currentSplitAmountBool];
                    const existingSplitAmountBool = updatedSplitAmountBool.findIndex((item) => item.linkedForm = linkedForm)

                    if(existingSplitAmountBool !== -1) {
                        updatedSplitAmountBool[existingSplitAmountBool] = { linkedForm, splitBool }
                    } else {
                        updatedSplitAmountBool.push({ linkedForm, splitBool });
                    }

                    return {
                        currentSplitAmountBool: updatedSplitAmountBool
                    }

                });

            }

        }

        const deleteKeyFromObjectAnswers = (object,stateKey,state,targetType) => {
            let keys = Object.keys(object);
            let indexKeys = 0;
            for(indexKeys;indexKeys<keys.length;indexKeys++)
            {
                if(keys[indexKeys].includes(stateKey,0)){
                    let indexOf = keys[indexKeys].indexOf(stateKey)
                    if(indexOf === 0){
                        if(targetType === "checkbox"){
                            if(keys[indexKeys] === state){
                                delete object[keys[indexKeys]]
                            }
                        }else{
                            if(keys[indexKeys] !== state){
                                delete object[keys[indexKeys]]
                            }
                        }

                    }
                }
            }
        }


        /** start main logic for display elements */
        try {
            appear = Appear(this.state.json.pageQuestion, this.state.appearStates)
        }catch (Exception){
            console.log("Exception Apper logic:"+Exception);
        }

        /** if there is no page question, check for pageForm  */
        if(this.state.json.pageQuestion.length === 0) {
            if(this.state.json.pageForm !== undefined){
                appear = Appear(this.state.json.pageForm.form, [])
            }

        }

        let splitOpt = [];
        if(this.state.json.maxAmount !== undefined){
            splitOpt['maxAmount'] = this.state.json.maxAmount
        }
        if(this.state.json.maxAmountType !== undefined){
            splitOpt['maxAmountType'] = this.state.json.maxAmountType
        }

        return(
            <div className={"WizardBox"}>
                    <div className={"headerContent"}>
                        <link href='https://fonts.googleapis.com/css?family=Lato:400,700&amp;' rel='stylesheet'/>
                        <Header text={""} allAmount={this.props.objects.wizzardPageAmount} currentAmount={this.state.json.pageProgress} logo={this.props.objects.wizardLogo} />
                    </div>
                    <div key={"border"} className="border">
                        <div className={"wholeWizardContainer"}>
                        <div className={"container"}>
                            <div className={"innerContentContainer"}>
                                <div className={"innerContainer"}>
                                    <div className={"mainYellow headlineQuestions"} >{this.state.json.pageHeadline}</div>
                                    {this.state.json.singleQuestion === "Yes" ?
                                        <div className={"textSize"}>
                                            <div className={"largeContent95 singleQuestion"} style={{ whiteSpace: 'pre-line', textAlign: "justify" }}>
                                                {this.state.json.question}
                                            </div>
                                        </div>
                                        : "" }
                                        {appear.map((questions,i) => {
                                            //console.log('appear')
                                            //console.log(appear)
                                            //console.log('questions')
                                            //console.log(questions)

                                        if(questions.questionId !== undefined ){
                                            x = (<div key={"div"+i}>
                                                    <div key={"textSize"+i} className={"textSize"} >
                                                        <div key={"row"+i} className={"row"}>
                                                           <div key={"largeContent95"+i} className={"largeContent95"} >
                                                               {questions.question}
                                                           </div>
                                                            <div key={"smallContent"+i} className={"smallContent"}>
                                                    {questions.popoverText !== "" ?
                                                        <button className={"toolTipBtn hideBtn"} id={this.state.json.wizardPageName+"info"+i} key={"toolTipBtn"+i} onClick={onClickInfoIcon} ><FaInfoCircle key={"infoIcon"+i} className={"infoIcon"}/></button>
                                                        : ""}
                                                        </div>
                                                        </div>
                                                             </div>
                                                    <div id={"div"+this.state.json.wizardPageName+"info"+i} key={"info"+i} >
                                                        <InfoModal key={"InfoModal"+i} ModalText={questions.popoverText} id={this.state.json.wizardPageName+"info"+i} popoverLink={questions.popoverLink}></InfoModal>
                                                    </div>

                                                    <label key={questions.questionId} id={questions.questionId} className={questions.helperText && questions.helperText !== "" ? "checkBoxLabel blockHelperText" : "checkBoxLabel"}>
                                                        <AiFillWarning key={"AiFillWarning"+i} className={"warning"} ></AiFillWarning>
                                                        <Inputs answers={selectedAnwsers} pageId={this.state.json.wizardPageId}
                                                                placeHolder={questions.placeholder} name={questions.valueName} forwardKey={"Inputs"+i} required={questions.required}
                                                                onchange={onChangeInputs} disabled={questions.disabled} id={questions.questionId}
                                                                types={questions.replyType} selectOpt={questions.choice} choiceId={questions.choiceId}
                                                                cssClass={questions.cssClass} formArray={formArray}
                                                                linkedFormNameInput={this.state.json.linkedForm}
                                                                linkedInputArray={this.state.json.linkedInput} onchangeSplitAmountConst={onChangeSplitAmount}
                                                                splitAmountArray={this.state.splitAmountArray} splitOpt={splitOpt}
                                                                userId={this.state.userId} value={questions.value} handlePaymentSuccess={onPaymentSuccess}
                                                                payment={this.state.payment} question={questions.question} icon={questions.icon}/>
                                                        {questions.label !== undefined ?
                                                            <i key={"iCheckBox"+i} className={"checkBoxLabelInput"}>{questions.label}</i> : ""
                                                        }
                                                        {hasLabel(questions.label)}
                                                        {hasIcon(questions.icon)}
                                                        {hasHelperText(questions.helperText)}
                                                        <div key={"divWarning"+i} className={"warningMessage"}>
                                                            <i key={"iWarningi"+i} className={"warningText"}>{questions.errorMessage}</i>
                                                        </div>
                                                    </label>
                                                    </div>)
                                        }else if(questions){
                                                {/** page with form */}
                                                //formElements(questions);

                                                x = <div key={"div"+i}>
                                                    <div key={"textSize"+i} className={"textSize"}>

                                                        <div key={"row"+i} className={"row"}>
                                                            <div key={"largeContent95"+i} className={"largeContent95"}>
                                                                {this.state.json.question}
                                                            </div>
                                                            <div key={"smallContent"+i} className={"smallContent"}>
                                                                {questions.popoverText !== "" ?
                                                                    <button key={"toolTipBtn"+i} className={"toolTipBtn hideBtn"} id={this.state.json.wizardPageName+"info"+i} onClick={onClickInfoIcon} ><FaInfoCircle key={"infoIcon"+i} className={"infoIcon"}/></button>
                                                                    : ""}
                                                            </div>
                                                        </div>
                                                        <div key={"info"+i} id={"div"+this.state.json.wizardPageName+"info"+i}>
                                                            <InfoModal key={"InfoModal"+i} ModalText={this.state.json.popoverText} id={this.state.json.wizardPageName+"info"+i}></InfoModal>
                                                        </div>
                                                    </div>
                                                    <Form formArray={questions} splitAmountArray={null} multipleForms={this.state.json.multipleForms}
                                                          formName={this.state.json.pageForm.formName} formHeadline={this.state.json.pageForm.formHeadline}
                                                          onClickForm={onClickForm} formValues={formArray} maxSteps={maxKeys} linkedForm={this.state.json.linkedForm}
                                                          extraFields={this.state.json.extraFields} splitType={null} maxAmount={null} maxAmountType={null}
                                                          modalValidation={onChangeValidation} checkModalInformationFunction={this.CheckModalElements}
                                                          question={this.state.json.question} checkMultiform={this.CheckMultiformCondition}
                                                    />
                                                </div>
                                        }


                                        return x
                                                })}

                                </div>
                            </div>

                        </div>
                        </div>
                    </div>
                    <div className={"buttonContent"}>
                            <div className={"flexContent"}>
                                <div id={"Placeholder"}></div>
                                <div className={"modalDiv"}></div>
                            </div>
                        <div className={"wholeWizardContainer"}>
                            <div className={"flexButtons"}>
                                {this.state.json.controls.map((control,i)=>{
                                        let single = false;
                                        if(this.state.json.controls.length === 1){
                                          single = true;
                                        }
                                        return (<Button key={i} onClick={onClick}
                                                        id={i}
                                                        splitForm={this.state.json.splitType}
                                                        typedesc={control.btnName}
                                                        control={control.attribut}
                                                        disabled={this.state.enable}
                                                        currentStep={this.state.json.wizardPageName}
                                                        prevStep={this.state.json.wizardPrevStep}
                                                        nextStep={this.state.json.wizardNextStep}
                                                        jump={this.state.json.jumpStep}
                                                        singular={single}/>
                                        )
                                    }
                                )}
                            </div>
                        </div>
                    </div>

                <script>

                </script>

            </div>

        );
    }


}


function checkRegXClass(elem) {


    if(elem !== undefined){
        if(elem.classList.contains("textReg")){
            return true
        }else if(elem.classList.contains("numberReg")){
            return true
        }else if(elem.classList.contains("percentageReg")){
            return true
        }else if( elem.classList.contains("amountReg")){
            return true
        }else if( elem.classList.contains("emailReg")){
            return true
        }else{
            return false
        }
    }

}

function validateData(elem,maskType = undefined){
    let emptyVal = true
    if(elem.value !== undefined && elem.value != null){
        emptyVal = false
    }
    if(maskType === "textReg"){
        if(!emptyVal){
            elem.value = elem.value.replace(/[^A-Za-zäöüÄÖÜß\s]/gi, '');
            return true
        }
    }else if(maskType === "numberReg"){
        if(!emptyVal){
            elem.value = elem.value.replace(/(\D)/g, '');
            return true
        }
    }else if(maskType === "percentageReg"){
        //console.log(elem.value)
        if(!emptyVal){
        const cardValue = elem.value
            .replace(/\D/g, '')
            .match(/(\d{2,4})(\d{0,4})(\d{0,4})(\d{0,4})/);
        elem.value = !cardValue[2]
            ? cardValue[1]
            : `${cardValue[1]}-${cardValue[2]}
          ${(`${cardValue[3] ? `-${cardValue[3]}` : ''}`)}
          ${(`${cardValue[4] ? `-${cardValue[4]}` : ''}`)}`;
        elem.value = elem.value.replace(/(\D)/g, '');
            return true
        }
    }else if(maskType === "amountReg"){
        if(!emptyVal){
            const cardValue = elem.value
                .replace(/\D/g, '')
                .match(/(\d{1,4})(\d{0,4})(\d{0,4})(\d{0,4})/);
            elem.value = !cardValue[2]
                ? cardValue[1]
                : `${cardValue[1]}-${cardValue[2]}
          ${(`${cardValue[3] ? `-${cardValue[3]}` : ''}`)}
          ${(`${cardValue[4] ? `-${cardValue[4]}` : ''}`)}`;
            elem.value = elem.value.replace(/(\D)/g, '');
            return true
        }
    }else if(maskType === "emailReg"){
        if(!emptyVal){
            const cardValue = elem.value.match(/^[a-zA-Z0-9-]+([.][a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+\.[A-Za-z]+$/);
            return cardValue !== null;

        }
    }

}

function hasLabel(label){
    if(label !== undefined){
        return <span className="checkmark"></span>

    }

}

function hasIcon(icon){
    if(icon !== undefined){

        if (icon === 'FaEuroSign') {
            return <FaEuroSign className="input-icon" />
        }else if (icon === 'FaAt') {
            return <FaAt className="input-icon" />
        }else if (icon === 'FaPhone') {
            return <FaPhone className="input-icon" />
        }
    }
}

function hasHelperText(helperText){
    if(helperText !== undefined && helperText !== null && helperText !== ""){
        return ( <div className={"helperText"}>
            {helperText}
        </div>)
    }
}




function checkState(type = undefined,buttonTarget = undefined) {

    let disable = false;
    let amountCounter = 0;
    let lastCount = 0;
    let content;
    let container;
    let currentModal;


    /** check states onClick event for non modal when push button "weiter" / "zurück" */
    if(type === undefined){

        if(buttonTarget === 'backstep') {
            /** don't check elements when go backstep */
            return false;
        }

        console.log('checkStates type === undefined')
        container = document.querySelector(".container")

        let modalInContainer = container.querySelectorAll(".modal")

        modalInContainer.forEach((currentObj,i) => {
            currentModal = currentObj;
            currentModal.style.display = "none";

        })

        content = container.querySelectorAll("input")

    }else if(type === "Modal"){
        console.log('checkStates type === Modal')
        /** check states onClickForm event for modal when push button "modalDoneButton" */

        let currentModalSelect
        /** select all new created modal`s in DOM/border/innerContainer  */
         container = document.querySelectorAll(".modal")
            container.forEach((currentObj,i) => {
                currentModal = currentObj;
                if(currentModal !== undefined){
                    // move current new created modal to DOM/buttonContent/modalDiv
                    //element.appendChild(currentModal)
                    currentModalSelect = currentModal
                }
            })

        /** select all input elemets from current modal*/
         content = currentModalSelect.querySelectorAll("input, select")
    }


    /** check all elemets from current page or modal */
    content.forEach((value,i) => {

        console.log('check all elemets from current page')
        //console.log(value)
        let parentElement = value.parentElement;

        /** did exist regex class to validate values ? */
        let regClassExists = checkRegXClass(value);
        let regClass;
        let validation = true;

        /** regex validation  */
        if(regClassExists){
            //console.log('regex validation')
            //console.log(value)
            value.classList.forEach((e, i ) =>{
                if(e.includes("Reg")){
                    regClass = e;
                }
            })
            validation = validateData(value, regClass)

            //console.log(validation)
        }


        let warning = parentElement.querySelector(".warning");
        let checkMark = parentElement.querySelector(".checkmark")
        let helperText = parentElement.querySelector(".helperText");
        let warningText = parentElement.querySelector(".warningMessage");

        if(warning === null){
            warning = parentElement.querySelector(".modal_warning");
            //console.log('warning is modal_warning')
        }

        if(value.required === true) {
            if (value.value === "" || !value.checked || !validation) {
                if (value.type === "checkbox" && !value.checked) {

                    disable = true
                    if (!checkMark.className.includes("missingInformation")){
                        checkMark.classList.add("missingInformation");
                        value.focus();
                    }

                    if(warningText !== null) {
                        warningText.style.display = "block";
                        warningText.style.marginTop = "5px";
                    }

                    if(helperText !== null) {
                        helperText.style.display = "none";
                    }

                } else if ((value.value === "") || !validation) {
                    disable = true
                    value.focus();

                    if (!value.className.includes("missingInformation")) {
                        value.className = value.className + " missingInformation"
                    }

                    if (warning !== null && !warning.className["baseVal"].includes("missingInformation")) {
                        warning.className["baseVal"] = warning.className["baseVal"] + " missingInformation"
                        //console.log(warningText);
                    }

                    if(warningText !== null) {
                        warningText.style.display = "block";
                    }

                    if(helperText !== null) {
                        helperText.style.display = "none";
                    }


                } else if (value.type === "radio" && !value.checked) {
                    /** @todo code for type radio select */



                }else {

                    value.classList.remove("missingInformation");

                    if(warningText !== null) {
                        warningText.style.display = "none";
                        warningText.style.marginTop = "0px";
                    }

                    if(helperText !== null) {
                        helperText.style.display = "block";
                    }

                    if (warning !== null) {
                        warning.classList.remove("missingInformation");
                    }

                    amountCounter++
                }

            }else{
                if(checkMark !== null){
                    checkMark.classList.remove("missingInformation");
                }

                if(warningText !== null) {
                    warningText.style.display = "none";
                    warningText.style.marginTop = "0px";
                }

                if(helperText !== null) {
                    helperText.style.display = "block";
                }
            }

        }else{
            amountCounter++
        }

        lastCount = i;

    })

    if(amountCounter === lastCount){
        disable = false;
    }

    return disable;

}

export default Body;


