import getAccessToken from '../api/getAccessToken';

async function sendWizardData(jsonObj) {

    const apiUrl = process.env.REACT_APP_DEV_API_URL;
    const url = `${apiUrl}/wizard/sendWizardData`;

    try {
        const tokenData = await getAccessToken();
        if (!tokenData || !tokenData.access_token) {
            throw new Error("Failed to retrieve access token");
        }

        const token = tokenData.access_token;

        //console.log('!!!!  send data over API')
        //console.log(jsonObj);

        const jsonString = JSON.stringify(jsonObj);
        console.log(jsonString);

        const response = await fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: jsonString
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
        }

        const data = await response.json();
        console.log('Response Data:', data);
        return data;

    } catch (error) {
        console.error('Error fetching data:', error);
    }

}

export default sendWizardData;