import SignatureCanvas from 'react-signature-canvas'
import "../Styles/Signature.css"


const Signature = ({pageId,id,name,keyId,choiceID}) => {

return( <SignatureCanvas penColor='black' canvasProps={{ className: 'signature'}} data-pageid={pageId} data-questionid={id} onChange={onchange} id={id} name={name} key={keyId} data-choiceid={choiceID}/> )

}



export default Signature