import React, {useState, useEffect, useRef} from 'react';
import { MultiSelect } from "react-multi-select-component";



const MultiSelectBox = ({ className, name, placeholder,unique, required = false, onChange, checkInformation ,elementData, mappedData = null,innerFormChange,linkedSelectOptions}) => {

    const [selected, setSelected] = useState([]);
    //const [additionalOptions, setAdditionalOptions] = useState([]);
    const [options, setOptions] = useState([]);
    const initialOptionsLoaded = useRef(false);
    const initialOptionsRef = useRef();


    /** Initialize options based on elementData or linkedSelectOptions */
    useEffect(() => {

        initialOptionsRef.current = Array.isArray(elementData["selecttypes"])
            ? transformSelectTypes(elementData["selecttypes"])
            : transformSelectTypes(linkedSelectOptions);

        if (!initialOptionsLoaded.current) {
            console.log('initial options:', initialOptionsRef.current);
            setOptions(initialOptionsRef.current);
            initialOptionsLoaded.current = true;
        }
    }, [elementData, linkedSelectOptions]);


    /** Process mappedData to update both selected and options as needed */
    useEffect(() => {
        if (mappedData !== null) {
            const selectedOptions = JSON.parse(mappedData);
            /** Set selected options */
            handleSelectedChange(selectedOptions);

            /** check whether there are new options that need to be added to options */
            const completeOptions = [...initialOptionsRef.current];

            const newOptionsNeeded = selectedOptions.filter(selectedOption =>
                !completeOptions.some(option => option.value === selectedOption.value));

            if (newOptionsNeeded.length > 0) {
                console.log('options bevor add:', completeOptions);
                console.log('new options from mappedData:', newOptionsNeeded);

                setOptions( [...completeOptions, ...newOptionsNeeded]);
            }
        }
    }, [mappedData]);


    /*useEffect(() => {
        // check whether there are new options that need to be added to options

        const newOptionsNeeded = additionalOptions.filter(so =>
            !options.some(option => option.value === so.value));

        if (newOptionsNeeded.length > 0) {
            console.log('options bevor add:', options);
            console.log('new options from additional:', newOptionsNeeded);
            setOptions(currentOptions => [...currentOptions, ...additionalOptions]);
        }
    }, [additionalOptions]);*/


    const handleSelectedChange = (selectedOptions) => {
        const filteredSelectedOptions = selectedOptions.filter(option => option !== null && option !== undefined);
        setSelected(filteredSelectedOptions);
        const selectedValues = JSON.stringify(filteredSelectedOptions);
        //console.log(selectedValues)
        document.getElementById(unique).value = selectedValues;
    };


    /*const handleCreateOption = (inputValue) => {
        //const newOption = { label: inputValue, value: inputValue.toLowerCase().replace(/\W/g, '') };
        const newOption = { label: inputValue, value: inputValue };
        setAdditionalOptions(current => [...current, newOption]);
        setSelected(currentSelected => [...currentSelected, newOption]);
    };*/

    //const handleCreateOption = (inputValue) => ({ label: inputValue, value: inputValue });


    const language = {
        "allItemsAreSelected": "Alle Optionen sind ausgewählt",
        "clearSearch": "Suche zurücksetzen",
        "clearSelected": "Auswahl löschen",
        "noOptions": "keine Optionen",
        "search": "oder neu eingeben",
        "selectAll": "Alle auswählen",
        "selectAllFiltered": "Alle auswählen (gefiltert)",
        "selectSomeItems": placeholder || "Bitte Auswählen...",
        "create": "Speichern",
    }

    /*const customValueRenderer = (selected, _options) => {
        return selected.length
            ? selected.map(( item ) => {
                return item && item.label ? item.label : ' Unbekannt';
            })
            : placeholder || "Bitte Auswählen...";
    };*/

    return (
        <div>
            <MultiSelect
                options={options}
                value={selected}
                onChange={handleSelectedChange}
                labelledBy="Select"
                hasSelectAll={false}
                overrideStrings={language}
                disableSearch={false}
                className={className}
                isCreatable={true}
                closeOnChangedValue={false}
                //onCreateOption={handleCreateOption}
                //valueRenderer={customValueRenderer}

            />
            <input
                type="hidden"
                id={unique}
                name={name}
                key={unique}
                required={required}
            />
        </div>
    );


};

export default MultiSelectBox;


function transformSelectTypes(selectTypes) {
    return selectTypes.map(obj => {
        const value = Object.values(obj)[0];
        return {
            label: value,
            value: value
        };
    });
}