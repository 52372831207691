import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import {FaInfoCircle, FaTrash} from "react-icons/fa";
import {AiFillWarning,IoIosArrowForward} from "react-icons/all.js";
import DatePickerComponent from "./DatePicker.js";
import LandAutoFiller from "./LaenderAutoComplete.js";
import SelectBox from "./SelectBox.js";
import MultiSelectBox from "./MultiSelectBox.js";
import { FaEuroSign } from "react-icons/fa";
import MBDatePickerComponent from "./DatePickerMB.js";
import MBLandAutoFiller from "./ScrollablePickerMB.js";


const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

const Modal = ({content,data = undefined,additionalFormItems,form,forwardKey,keyID,onChange,checkInformation,formName = null,formHeadline= null,question = null,linkedModalId = null}) => {

    //console.log('Modal data');
    //console.log(data);

    //console.log('Modal content')
    //console.log(content)

    /** callback function onChange is "onChangeValidation()" from Body to valuate regex */
    //console.log('Modal onChange callback')
    //console.log(onChange)

    /** callback function checkInformation is "CheckModalElements()" from Body to valuate required elements and enable buttons  */
    //console.log('Modal checkInformation callback')
    //console.log(checkInformation)

    /** for control visibility of element by appear condition  */
    const [inputStates, setInputStates] = useState({});


    useEffect(() => {
        /** Initializes the states control visibility of all fields to their default values or empty */
        let initialStates = {};

        let dataToMapArray = [];
        if(data !== undefined){
            /** search in mappedData fo all keys, named beginning with 'formName', to generate an array of keys from all modals*/
            let filteredKeys = Object.keys(data).filter(key => key.startsWith(formName));

            if(filteredKeys !== undefined){
                let dataArray = data[formName+""+keyID]
                if(dataArray !== undefined){
                    dataToMapArray = dataArray["array"]
                }

            }
        }

        for (const [key, field] of Object.entries(content)) {
            initialStates[field.name] = dataToMapArray[field.name] || '';
        }
        setInputStates(initialStates);
    }, [content]);



    /** element call back for control visibility of element by appear condition  */
    const innerFormChange = (e) => {
        const { name, value } = e.target;
        setInputStates(prev => ({ ...prev, [name]: value }));



        /** due to the asynchronous rendering of React, we have to set a pause so that the elements are rendered before the check */
        setTimeout(() => {
            //console.log('use effect after render')
            checkInformation();
        }, 40);

    };

    /** function for control visibility of element by appear condition  */
    const checkVisibility = (appearConditionsArray) => {
        /** Check whether there are any conditions */
        if (!appearConditionsArray || appearConditionsArray.length === 0) {
            return true; /** No conditions defined, so always display */
        }

        /** Check each group of conditions in the array */
        return appearConditionsArray.some(appearConditions => {
            const { selectionAnd, selectionOr } = appearConditions;

            /** Check whether all selectionAnd conditions are fulfilled */
            const andConditionsMet = !selectionAnd || selectionAnd.length === 0 || selectionAnd.every(condition => {
                const [field, expectedValue] = condition.selection.split(':');
                return inputStates[field] === expectedValue;
            });

            /** Check whether at least one selectionOr condition is fulfilled */
            const orConditionsMet = !selectionOr || selectionOr.length === 0 || selectionOr.some(condition => {
                const [field, expectedValue] = condition.selection.split(':');
                return inputStates[field] === expectedValue;
            });

            /** The field is visible if either all And conditions or at least one Or condition is fulfilled */
            return andConditionsMet && orConditionsMet;
        });
    };




    /** generate array list of formula elements for modal */
    let array = [];
    for (const [key, value] of Object.entries(content)) {
        array.push(value)
    }
    //console.log('array of content')
    //console.log(array)


    const modalRoot = document.querySelector('.modalDiv') || document.body;

    if(content !== ""){
        {/*console.log("render list of formula elements for modal")*/}
        return ReactDOM.createPortal(<div className="modal" id={formName+keyID} data-question={question} data-linked={linkedModalId} style={{display:"none"}}>
            <div className="modal_content">
                <div className={"modal_header"}>
                    <div className={"row"}>
                        <div className={"largeContent95"}>
                            <p className={"modal_headline"} key={"headLine"}>
                                {formHeadline}
                            </p>
                        </div>
                        <div className={"smallContent"}>
                            <span style={{float:"right", fontSize: "19px", color: "#9badcc"}} className="close cursor">&times;</span>
                        </div>
                    </div>
                </div>
                <div className={"modal_content_container"}>
                    <div className={"modal_content_innerContainer"}>
                        <div className={"modal_innerConntent innerContent"}>
                            {/** render list of formula elements for modal */}
                            {array.map((value,i) => {
                                let visibleClass = "";
                                let required = value["required"];
                                // console.log(inputStates)
                                if (!checkVisibility(value.appear)) {
                                    /** dont display this element */
                                    visibleClass = 'hiddenDiv';
                                    required = "false";
                                }
                                {/** return jsx for each input element from array */}
                                return (<div key={"ObjectDiv"+i} className={visibleClass} >
                                    {/** generate label from input with name and info to optional or required */}
                                    <div key={"Object"+i} className={"modalQuestionTitles mrgn15"}>
                                        <div><label key={"label"+i} id={i}>{value["label"]}</label></div>
                                        {value["required"] === "true" ?
                                            <div className={"ModalFlexInfoOptional"}>Pflichtfeld</div> : ""
                                        }
                                    </div>
                                    {/** generate and initialise input element */}
                                    {checkMappedData(data, value, required, i, form, keyID, formName, forwardKey+i,onChange,checkInformation,innerFormChange)}
                                    {/** set helper text to input field */}
                                    {value["helperText"] && (
                                        <div className={"helperText"}>
                                            {value["helperText"]}
                                        </div>
                                    )}
                                    {/** set error box to input */}
                                    <div className={"warningMessage"}>
                                        <AiFillWarning className={"modal_warning"} ></AiFillWarning>
                                        {value["errorMessage"] && (
                                            <i className={"warningText"}>{value["errorMessage"]}</i>
                                        )}
                                    </div>
                                </div>)
                            })}
                            {additionalItems(additionalFormItems,onChange,checkInformation)}
                        </div>
                    </div>
                </div>
                <div className={"modal_footer"}>
                    <hr/>
                    {detectDataInsideModal(data,formName,keyID)}
                </div>
            </div>
        </div>,modalRoot
        );
    }else{
        {/** default create empty modal */}
        return ReactDOM.createPortal(
            <div className="modal" id={formName+keyID} data-question={question} data-linked={linkedModalId} style={{display:"none"}} >
                <div className="modal_content">
                    <div className={"modal_header"}>
                        <div className={"row"}>
                            <div className={"halfContent"}>
                                <p className={"modal_headline"}>{formHeadline}</p>
                            </div>
                            <div className={"halfContent"}>
                                <span className="close">&times;</span>
                            </div>
                        </div>
                    </div>
                    <div className={"modal_innerConntent"}>
                    </div>
                    <div className={"modal_footer"}>
                        <hr/>
                        {detectDataInsideModal(data,formName,keyID)}
                    </div>
                </div>
            </div>,modalRoot
        );
    }
}



function detectDataInsideModal(data,formName,keyId) {

    let mappedData = false
    if(data !== undefined && data[formName+keyId] !== undefined){
        mappedData = true
    }

    if(mappedData === false){
        /** if modal has no mapped data, show single button done */
        return (<button key={"modalDone"} id={"modalDone"} className={"modalDoneButton"} disabled><div className={"row"}>
            <div className={"largeContent95"}>
                <b >Bestätigen</b>
            </div>
            <div className={"smallContentButtonRight"}>
                <IoIosArrowForward className={"vectorRight"}/>
            </div>
        </div></button>)
    }else{
        /** else show two buttons, delete and done*/
        return (<div className={"row"}>
                    <div className={"halfContent"}>
                        <button key={"modalDelete"} id={"modalDelete"} className={"modalDeleteBtn"}><FaTrash/>&nbsp;Remove</button>
                    </div>
                    <div className={"halfContent"}>
                        <button key={"modalDone"} id={"modalDone"} className={"modalDoneButton"} disabled><div className={"row"}>
                            <div className={"largeContent95"}>
                                <b >Bestätigen</b>
                            </div>
                            <div className={"smallContentButtonRight"}>
                                <IoIosArrowForward className={"vectorRight"}/>
                            </div>
                        </div></button>
                    </div>
                </div>)
    }

}


/** to generate and initialise formula elements for modals */
function checkMappedData(mappedData = null,elementData = null,elementRequired,id,form,keyId,formName,forwardKey,onChange,checkInformation,innerFormChange) {

    /** mappedData contains the information how was set by user to initialise elements */
    //console.log("checkMappedData")
    //console.log(mappedData);

    /** elementData contains the elements to generate */
    //console.log(elementData);
    //console.log(id);    // is ID from form element
    //console.log(keyId); //is ID from form
    //console.log(form);
    //console.log(formName);
    //console.log(forwardKey);  // is same ID as id, from form element

    const motherId = formName+""+keyId;
    const unique = motherId+':'+forwardKey;

    let filteredKeys
    let linkedSelectOptions = [];


    if(mappedData !== null){
        /** search in mappedData fo all keys, named beginning with 'formName', to generate an array of keys from all modals */
        filteredKeys = Object.keys(mappedData).filter(key => key.startsWith(formName));
        //console.log('filteredKeys');
        //console.log(filteredKeys);
    }

    /** manage linked select, if selecttypes is not array of options */
    if(elementData['selecttypes'] && !Array.isArray(elementData["selecttypes"])){

        /** get linked form and linked value from string*/
        const linkedFormStrings = elementData['selecttypes'].split(":");
        const primaryFilterKey = linkedFormStrings[0];
        const valueKey = linkedFormStrings[1];

        /** filter all linked form from amountOfForms */
        const linkedSelectKeys = Object.keys(mappedData).filter(key => key.startsWith(primaryFilterKey));

        /** get searched value from filtered forms and build array of objects */
        linkedSelectKeys.forEach((key, index) => {
            const array = mappedData[key]["array"];
            if (array && array[valueKey]) {
                let optionObj = {};

                optionObj[index] = array[valueKey];
                linkedSelectOptions.push(optionObj);
            }
        });

    }


    /** make required boolean value */
    let required = elementRequired === "true";


    /** get data to initialise fields if exist */
    if(filteredKeys !== undefined){
        let dataToMapArray = mappedData[formName+""+keyId]
        //console.log("dataToMapArray");
        //console.log(dataToMapArray);
        if(dataToMapArray !== undefined && dataToMapArray !== null){
            mappedData = dataToMapArray["array"][elementData["name"]]
            //console.log("MappedDataValue")
            //console.log(mappedData);
        }else{
            mappedData = null;
        }
    }else{
        mappedData = null;
    }

    /** set multiple callback function for element */
    const moreCalls = (e) => {
        innerFormChange(e);
        //checkInformation();
        onChange(e);
    }

    /** if exist mappedData generate input field and initialise with data */
    if(mappedData !== null){

        /** property description of userArray is special, we can use it for linked multi select box options.
         * Therefore, it must be unique, and we have to add a number.
         * When we don't use it for multiselect we have to clean the number  */
        if('description' === elementData["name"] && elementData["type"] !== "multiselect") {
            //console.log('description change')
            //console.log(elementData["name"])
            //console.log(mappedData)

            const matches = mappedData.match(/([a-zA-Z]+)\s+\d+/);

            if (matches && matches.length === 2) {
                mappedData = matches[1];
                //console.log('description change')
                //console.log(mappedData)
                //targetID = parseInt(matches[2], 10);
            }
        }

        if(elementData["type"] === "date" && !isMobile){
            const className = "input modalInput " +elementData["cssClass"]
            const name = elementData["name"]
            const placeholder = elementData["placeholder"]
            const onchange = onChange;
            return DatePickerComponent({className,name,placeholder,unique,required,onchange,checkInformation,mappedData,innerFormChange})

        }else if(elementData["type"] === "date" && isMobile){
            const className = "input modalInput " +elementData["cssClass"]
            const name = elementData["name"]
            const placeholder = elementData["placeholder"]
            const onchange = onChange;
            return MBDatePickerComponent({className,name,placeholder,unique,required,onchange,checkInformation,mappedData,innerFormChange})

        }else if(elementData["label"] === "Land" || elementData["type"] === "autoCompleteCountry"){
            const className = "input modalInput " +elementData["cssClass"]
            const placeholder = elementData["placeholder"]
            const name = elementData["name"]
            return LandAutoFiller({className,name,placeholder,unique,required,onChange,checkInformation,mappedData,innerFormChange})

        }else if(elementData["type"] === "textarea"){
            return <textarea id={unique} name={elementData["name"]} className={"input modalInput " +elementData["cssClass"]} key={unique}
                            placeholder={elementData["placeholder"]} required={required} onChange={moreCalls} defaultValue={mappedData}/>

        }else if(elementData["type"] === "select"){
            const className = "select custom-select modalInput"
            const placeholder = elementData["placeholder"]
            const name = elementData["name"]
            return SelectBox({className,name,placeholder,unique,required,onChange,checkInformation,elementData,mappedData,innerFormChange,linkedSelectOptions})

        }else if(elementData["type"] === "multiselect"){
            const className = "multiselect modalInput"
            const placeholder = elementData["placeholder"]
            const name = elementData["name"]
            return MultiSelectBox({className,name,placeholder,unique,required,onChange,checkInformation,elementData,mappedData,innerFormChange,linkedSelectOptions})

        }else if(elementData["type"] === "checkBox"){
            return <input id={unique} name={elementData["name"]} className={"checkBox modalInput"} key={unique} type={elementData["type"]}
                          required={required} data-choicevalue={""} checked={true} onChange={moreCalls}/>

        }else if(elementData["type"] === "none"){


        }else if(elementData["type"] === "radioSelect") {
            return (
                <div className={"radio-select-group"}>
                    {elementData["selecttypes"].map((option, index) => {
                        // console.log("defined: ")
                        // console.log(defindedValue)
                        // console.log("keyOption")
                        // console.log(i)
                        if(mappedData === option[index]){
                            return (<label key={index} className={"checkBoxLabel"}>
                                <input
                                    type={"radio"}
                                    id={unique+index}
                                    key={unique+index}
                                    name={elementData["name"]}
                                    value={option[index]}
                                    checked={mappedData === option[index]}
                                    onChange={moreCalls}
                                    className={"checkBox modalInput"}
                                    required={required}
                                />
                                {option[index] !== undefined ?
                                    <i key={"iCheckBox"+index} className={"checkBoxLabelInput"}>{option[index]}</i> : ""
                                }
                                <span className="checkmark"></span>
                            </label>)

                        }else{
                            return (<label key={index} className={"checkBoxLabel"}>
                                <input
                                    type={"radio"}
                                    id={unique+index}
                                    key={unique+index}
                                    name={elementData["name"]}
                                    value={option[index]}
                                    onChange={moreCalls}
                                    className={"checkBox modalInput"}
                                    required={required}
                                />
                                {option[index] !== undefined ?
                                    <i key={"iCheckBox"+index} className={"checkBoxLabelInput"}>{option[index]}</i> : ""
                                }
                                <span className="checkmark"></span>
                            </label>)
                        }

                    })}
                </div>
            );
        } else {
            return (<div className={"input-group"}>
                <input id={unique} name={elementData["name"]} className={"input modalInput " +elementData["cssClass"]} key={unique} type={elementData["type"]}
                         placeholder={elementData["placeholder"]} required={required} defaultValue={mappedData} onChange={moreCalls}/>
                {(elementData["icon"] === 'FaEuroSign') &&
                    <FaEuroSign  className="input-icon" />
                }
            </div>)
        }


    }else{
        /** if not exist mappedData, only generate input field */

        if(elementData["type"] === "date" && !isMobile){
            const className = "input modalInput " +elementData["cssClass"]
            const placeholder = elementData["placeholder"]
            const name = elementData["name"]
            return DatePickerComponent({className,name,placeholder,unique,required,onChange,checkInformation,mappedData,innerFormChange})

        }else if(elementData["type"] === "date" && isMobile){
            const className = "input modalInput " +elementData["cssClass"]
            const placeholder = elementData["placeholder"]
            const name = elementData["name"]
            return MBDatePickerComponent({className,name,placeholder,unique,required,onChange,checkInformation,mappedData,innerFormChange})

        }else if(elementData["label"] === "Land" || elementData["type"] === "autoCompleteCountry"){
            const className = "input modalInput " +elementData["cssClass"]
            const placeholder = elementData["placeholder"]
            const name = elementData["name"]
            return LandAutoFiller({className,name,placeholder,unique,required,onChange,checkInformation,mappedData,innerFormChange})

        }else if(elementData["type"] === "textarea"){
            return <textarea id={unique} name={elementData["name"]} className={"input modalInput " +elementData["cssClass"]} key={unique}
                         placeholder={elementData["placeholder"]} required={required} onChange={moreCalls}/>

        }else if(elementData["type"] === "select"){
            const className = "select custom-select modalInput"
            const name = elementData["name"]
            const placeholder = elementData["placeholder"]
            return SelectBox({className,name,placeholder,unique,required,onChange,checkInformation,elementData,innerFormChange,linkedSelectOptions})

        }else if(elementData["type"] === "multiselect"){
            const className = "multiselect modalInput"
            const placeholder = elementData["placeholder"]
            const name = elementData["name"]
            return MultiSelectBox({className,name,placeholder,unique,required,onChange,checkInformation,elementData,innerFormChange,linkedSelectOptions})

        }else if(elementData["type"] === "checkBox"){
            return <input id={unique} name={elementData["name"]} className={"checkBox modalInput"} key={unique} type={elementData["type"]}
                          required={required}  data-choicevalue={""} onChange={moreCalls}/>

        }else if(elementData["type"] === "none"){


        }else if(elementData["type"] === "radioSelect") {
            return (
                <div className={"radio-select-group"}>
                    {elementData["selecttypes"].map((option,index) => (
                        <label key={index} className={"checkBoxLabel"}>
                            <input
                                type={"radio"}
                                id={unique+index}
                                key={unique+index}
                                name={elementData["name"]}
                                value={option[index]}
                                onChange={moreCalls}
                                className={"checkBox modalInput"}
                                required={required}
                            />
                            {option[index] !== undefined ?
                                <i key={"iCheckBox"+index} className={"checkBoxLabelInput"}>{option[index]}</i> : ""
                            }
                            <span className="checkmark"></span>
                        </label>
                    ))}
                </div>
            );
        }else {
            return (<div className={"input-group"}>
                <input id={unique} name={elementData["name"]} className={"input modalInput " +elementData["cssClass"]} key={unique} type={elementData["type"]}
                       placeholder={elementData["placeholder"]} required={required} onChange={moreCalls}/>
                {(elementData["icon"] === 'FaEuroSign') &&
                    <FaEuroSign  className="input-icon" />
                }
            </div>)


        }

    }
}


function additionalItems(formData = null,onChange,checkInformation){
   // console.log("AdditonalItems");
   // console.log(formData);
    let additionalItems = [];

    const moreCalls = (e) => {
        checkInformation();
        onChange(e);
    }

    if(formData != null){
        for(const [key,value] of Object.entries(formData)){

                for(const [index,val] of Object.entries(value)){

                    for(const [i,v] of Object.entries(val)){
                    if(v["type"] !== "select"){
                        let obj;
                        //console.log(v["type"]);
                        if(v["type"] !== "textarea"){
                            obj = <input key={"additionalItemsInput"+v["name"]} id={v["name"]} className={"input"} type={v["type"]} name={v["name"]} onChange={moreCalls} />
                        }else{
                            obj = <textarea key={"additionalItemsInput"+v["name"]} id={v["name"]} className={"input"} name={v["name"]} onChange={moreCalls}></textarea>
                        }

                        /** @todo add other input elemts  */

                        additionalItems[i]=(<div>
                            <div className={"modalQuestionTitles mrgn15"}>
                                <label key={"additionalItemsLabel"+v["name"]} id={v["name"]}>{v["label"]}</label>
                            </div>
                            {obj}
                        </div>)
                    }
                }
            }
        }

        return additionalItems.map((values,keys) => {
             return values
        })

    }else{
        return <div key={"emptyItem"}></div>
    }

}



export default Modal;