
/** appear logic vor questions */
function Appear(json,appearArray) {
   // console.log(json)
    let state = []
    let arrayCounter = 0


    if(appearArray.lenght === 0){
        return "nothing"
    }

    if(json !== undefined){
    json.forEach((question, i) => {
        if(Array.isArray(question.appear)){
            question.appear.forEach((appearState,a) =>{

                let andState = true, orState = false, notOrState = true, notAndState = true;

                // check selectionAnd
                if (appearState.selectionAnd.length !== 0) {
                    andState = appearState.selectionAnd.every(select =>
                        appearArray.some(appearSingleKeys => select.selection === appearSingleKeys.selection));
                }

                // check selectionOr
                if (appearState.selectionOr.length !== 0) {
                    orState = appearState.selectionOr.some(select =>
                        appearArray.some(appearSingleKeys => select.selection === appearSingleKeys.selection));
                }

                // check notOr
                if (appearState.notOr && appearState.notOr.length !== 0) {
                    notOrState = !appearState.notOr.some(select =>
                        appearArray.some(appearSingleKeys => select.selection === appearSingleKeys.selection));
                }

                // check notAnd
                if (appearState.notAnd && appearState.notAnd.length !== 0) {
                    notAndState = appearState.notAnd.every(select =>
                        !appearArray.some(appearSingleKeys => select.selection === appearSingleKeys.selection));
                }

                // Stelle sicher, dass alle Bedingungen erfüllt sind
                if (andState && orState && notOrState && notAndState) {
                    // Hinzufügen des aktuellen Elements zu state
                    state.push(question);
                }

                /*let allreadyCheckedState = false;
                let counter = 0;
                if(appearState.selectionAnd.length !== 0){

                    appearArray.forEach((appearSingleKeys,b) => {
                        if(appearState.selectionAnd.some(select => select.selection === appearSingleKeys.selection) && counter !== appearState.selectionAnd.length){
                            counter = counter +1
                        }

                        if(counter === appearState.selectionAnd.length){
                            if(state.length !== 0 ){
                                if(state.some(quest => quest.questionId === question.questionId)){
                                    allreadyCheckedState = true;
                                }
                            }
                            if(!allreadyCheckedState){
                                state[arrayCounter] = question;
                                arrayCounter = arrayCounter +1
                            }

                        }
                    })

                }else if(appearState.selectionOr.length !== 0){

                    appearArray.forEach((appearSingleKeys,b) =>{
                        if(appearState.selectionOr.some(select => select.selection === appearSingleKeys.selection) && counter === 0){

                            counter = counter +1
                            if(state.length !== 0 ){
                                if(state.some(quest => quest.questionId === question.questionId)){
                                    allreadyCheckedState = true;
                                }
                            }
                            if(!allreadyCheckedState){
                                state[arrayCounter] = question;
                                arrayCounter = arrayCounter +1
                            }

                        }
                    })
                }*/

            })
        }else{
           // console.log(question)
            if(question.pageForm !== undefined){
              // console.log("definiert")
                if(Array.isArray(question)){
                    state = question.pageForm
                }
            }else{

                if(appearArray && Object.keys(appearArray).length > 0){
                    const lastObject = appearArray[appearArray.length - 1];
                    question.lastAnswer = lastObject.selection;
                }


                state[arrayCounter] = question;
                arrayCounter = arrayCounter +1
            }


        }



    })

    }


return state;


}

export default Appear