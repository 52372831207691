import React, { useState } from 'react';
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";

const Checkout = ({paymentData,userId,payerData,onPaymentSuccess }) => {

    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

    const onCreateOrder = (data,actions) => {
        console.log(paymentData);
        const purchaseUnit = {
            amount: {
                value: paymentData.totalAmount.toFixed(2),
                currency: paymentData.currency,
                breakdown: {
                    item_total: {
                        currency_code: paymentData.currency,
                        value: paymentData.value.toFixed(2)
                    },
                    tax_total: {
                        currency_code: paymentData.currency,
                        value: paymentData.vatAmount.toFixed(2)
                    }

                }
            },
            custom_id: userId,
            items: [
                {
                    name: paymentData.description,
                    unit_amount: {
                        currency_code: paymentData.currency,
                        value: paymentData.value.toFixed(2)
                    },
                    tax: {
                        currency_code: paymentData.currency,
                        value: paymentData.vatAmount.toFixed(2)
                    },
                    quantity: "1"
                }
            ]
        };

        if (payerData.email) {
            purchaseUnit.payer = {
                email_address: payerData.email
            };
        }

        let applicationContext = {};

        if(!payerData.shipping) {
            applicationContext.shipping_preference = "NO_SHIPPING";
        }


        if (payerData.country && payerData.zipcode && payerData.city && payerData.street && payerData.firstname && payerData.lastname ) {
            purchaseUnit.shipping = {
                name: {
                    full_name: payerData.firstname + " " + payerData.lastname
                },
                address: {
                    address_line_1: payerData.street,
                    address_line_2: "",
                    admin_area_2: payerData.city,
                    admin_area_1: "",
                    postal_code: payerData.zipcode,
                    country_code: payerData.country
                }
            };
        }



        return actions.order.create({
            purchase_units: [purchaseUnit],
            application_context: applicationContext
        });
    }

    const onApproveOrder = (data,actions) => {
        return actions.order.capture().then((details) => {

            const errorDetail = details?.[0];

            if (errorDetail?.issue === "INSTRUMENT_DECLINED") {

                // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()

                // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/

                return actions.restart();

            } else if (errorDetail) {

                // (2) Other non-recoverable errors -> Show a failure message

                throw new Error(

                    `${errorDetail.description} (${details.debug_id})`,

                );

            } else {
                onPaymentSuccess(details);
            }


        });
    }

    return (
        <div className="checkout">
            {isPending ? <p>LOADING...</p> : (
                <>
                    {/*<select value={currency} onChange={onCurrencyChange}>
                        <option value="USD">💵 USD</option>
                        <option value="EUR">💶 Euro</option>
                    </select>*/}
                    <PayPalButtons
                        style={{ layout: "vertical" }}
                        createOrder={(data, actions) => onCreateOrder(data, actions)}
                        onApprove={(data, actions) => onApproveOrder(data, actions)}
                    />
                </>
            )}
        </div>
    );
}

export default Checkout;