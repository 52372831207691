import React from "react";

import "../Styles/Input.css"
import "../Styles/Wizzard.css"

import 'tippy.js/dist/tippy.css'
import {FaPlus, FaUserCircle, FaLevelUpAlt } from "react-icons/fa"
import { IoBusinessOutline } from "react-icons/io5";
import Modal from "./Modal.js";
import {BsBox} from "react-icons/all";

const Form = ({formArray,splitAmountArray,multipleForms,formName,formHeadline,onClickForm,formValues,maxSteps,linkedForm,extraFields,
                  splitType,maxAmount,maxAmountType,modalValidation,checkModalInformationFunction,question,checkMultiform}) => {

    let x = []  ;
    let counter ;
    let mappedArray ;

    let splitArray = undefined;
    let linkedFormNamesSet = undefined;
    let splitValue = "";

    /** first split linkedForm into formname and followed by form values, second split formname wenn given more comma separated
     *  "children,heritage,husband:fullname:company:firstname:name"  and generate a set ov falues    */
    if (linkedForm !== undefined && linkedForm !== null && linkedForm !== '') {
        const linkedFormStrings = linkedForm.split(":");
        let linkedFormNames = linkedFormStrings[0].split(',');
        linkedFormNamesSet = new Set(linkedFormNames);
    }



    //console.log('formArray')
    //console.log(formArray)

    console.log("LinkedForm");
    console.log(linkedForm)

    //console.log("mappedArray");
    //console.log(mappedArray)

    console.log('formValues')
    console.log(formValues)

    console.log('multipleForms');
    console.log(multipleForms)
    console.log(maxSteps);

    if(linkedFormNamesSet !== undefined ) {
        /** action for linked formulas */
        console.log('linkedFormNamesSet is defined');
        console.log(linkedFormNamesSet);


        counter = 0;
        for (const [key, value] of Object.entries(formValues)) {

            //if (Array.from(linkedFormNamesSet).some(formName => key.includes(formName))) {
            if (Array.from(linkedFormNamesSet).some(formName => new RegExp(`^${formName}\\d+$`).test(key))) {

                x[counter] = (<div key={formName+counter+"ModalContainer"} id={formName+counter+"ModalContainer"}>
                    <Modal key={"modal"+counter} content={formArray} data={formValues}
                           additionalFormItems={extraFields} form={formArray} forwardKey={counter}
                           keyID={counter} formName={formName} formHeadline={formHeadline}
                           onChange={modalValidation} checkInformation={checkModalInformationFunction}
                           question={question} linkedModalId={key}
                    />
                    {/** create button open user form with percentage split  */}
                    <div key={"formElementUser"+counter} id={counter} className={"formElementUser"} onClick={onClickForm}>
                        <div key={"row"+counter} id={counter} className={"row center"}>
                            <div className={"smallContent"}>
                                {getLinkedFormSymbol(value,counter)}
                            </div>
                            <div key={"thirdQuaterContend"+counter} id={counter} className={"thirdQuaterContend"}>
                                {getLinkedFormDisplayName(value)}
                            </div>
                            <div key={"quaterContent"+counter} id={counter} className={"quaterContent mainYellow"}>
                                {declareSplitType(splitType,maxAmount,maxAmountType,splitValue,maxSteps,counter)}
                            </div>
                        </div>
                    </div>
                    {linkedVisualElement(formValues,formName,counter,linkedForm)}
                </div>)

                counter++;
            }
        }


    }else if(multipleForms === "Yes" || checkMultiform()) {
        /** action for multiple formulas */

        for(counter = 0; counter < maxSteps; counter++){
            //console.log("FormularFelder")
            //console.log(counter);
            //console.log("linkedFormName")
            //console.log(linkedFormName(counter,linkedForm));

            /** if is set splitAmountArray get current by form name and element counter */
            if(splitAmountArray !== undefined && splitAmountArray !== null){

                let currentSplitAmountArray = splitAmountArray.find(obj => obj.linkedForm === formName);
                splitArray = currentSplitAmountArray && currentSplitAmountArray.splitArray;

                if (splitArray !== undefined && splitArray !== null) {
                    splitValue = splitArray[formName+counter] ? splitArray[formName+counter] : "";
                    //console.log('splitValue')
                    //console.log(splitValue)
                }

            }

            if(counter === maxSteps && linkedForm !== ""){
                //console.log('continue !!!!!!!!!!!!!!')
                //console.log(maxSteps)
                //console.log(linkedForm)
                continue
            }

            /** check if userData exist to form */
            mappedArray = mappedArrayFunction(formValues,formName,counter,linkedForm)
            console.log("check if userData exist to form")
            console.log(mappedArray)
            console.log(linkedForm)

            if(mappedArray === undefined && linkedForm === ""){
                console.log("create new hidden modal without data and show button create")

                /** create new hidden modal without data and show button create  */
                x[counter]= (<div key={formName+counter+"ModalContainer"} id={formName+counter+"ModalContainer"}>
                    <Modal key={"modal"+counter} content={formArray} data={formValues}
                           additionalFormItems={extraFields} form={formArray} forwardKey={counter}
                           keyID={counter} formName={formName} formHeadline={formHeadline}
                           onChange={modalValidation} checkInformation={checkModalInformationFunction} question={question}
                    />
                    <div key={"formElement"+counter} id={counter} className={"formElement"} onClick={onClickForm}>
                        {/** create button "+ Hinzufügen" */}
                        <div key={"row"+counter} className={"row center"}>
                            <div key={"smallContent"+counter} className={"smallContent"}>
                                <FaPlus key={"formElememtPlusIcon"+counter} className={"formElememtPlusIcon"}/>
                            </div>
                            <div key={"thirdQuaterContend"+counter} className={"thirdQuaterContend"}> Hinzufügen</div>
                        </div>
                    </div>
                </div>)
            }else {
                if (mappedArray !== undefined) {
                    console.log("create Modal mappedArray")
                    console.log(mappedArray)
                    /** create new hidden modal with user data */
                    x[counter] = (<div key={formName+counter+"ModalContainer"} id={formName+counter+"ModalContainer"}>
                        <Modal key={"modal"+counter} content={formArray} data={formValues}
                               additionalFormItems={extraFields} form={formArray} forwardKey={counter}
                               keyID={counter} formName={formName} formHeadline={formHeadline}
                               onChange={modalValidation} checkInformation={checkModalInformationFunction}
                               question={question}
                        />
                        {/** create button open user form with percentage split  */}
                        <div key={"formElementUser"+counter} id={counter} className={"formElementUser"} onClick={onClickForm}>
                            <div key={"row"+counter} id={counter} className={"row center"}>
                                <div className={"smallContent"}>
                                    {mappedArraySymbol(formValues,formName,counter,linkedForm)}
                                </div>
                                <div key={"thirdQuaterContend"+counter} id={counter} className={"thirdQuaterContend"}>
                                    {mappedArray}
                                </div>
                                <div key={"quaterContent"+counter} id={counter} className={"quaterContent mainYellow"}>
                                    {declareSplitType(splitType,maxAmount,maxAmountType,splitValue,maxSteps,counter)}
                                </div>
                            </div>
                        </div>
                        {linkedVisualElement(formValues,formName,counter,linkedForm)}
                    </div>)
                }
            }
        }



    }else{
        /** action for single formulas */
        console.log('singleForms');
        /** check if userData exist to form */
        mappedArray = mappedArrayFunction(formValues,formName,0,linkedForm)

        if(mappedArray !== undefined){
            /** create new hidden modal with data and show button open  */
            {/*console.log('create new hidden modal with data')*/}
            x = <div key={formName+"0ModalContainer"} id={formName+"0ModalContainer"}>
                <Modal key={"modal"+0} content={formArray} data={formValues} additionalFormItems={extraFields}
                       form={formArray} forwardKey={0} keyID={0} formName={formName} formHeadline={formHeadline}
                       onChange={modalValidation} checkInformation={checkModalInformationFunction} question={question}
                />
                {/** create button open user form with percentage split  */}
                <div key={"formElementUser0"} id={"0"} className={"formElementUser"} onClick={onClickForm}>
                    <div key={"row0"} id={"0"} className={"row center"}>
                        <div className={"smallContent"}>
                            {mappedArraySymbol(formValues,formName,0,linkedForm)}
                        </div>
                        <div key={"thirdQuaterContend0"} id={"0"} className={"thirdQuaterContend"}>
                            {mappedArray}
                        </div>
                        <div key={"quaterContent0"} id={"0"} className={"quaterContent mainYellow"}>
                            {declareSplitType(splitType,maxAmount,maxAmountType,"",maxSteps, counter)}
                        </div>

                    </div>
                </div>
                {linkedVisualElement(formValues,formName,0,linkedForm)}
            </div>
        }else {
            /** create new hidden modal without data and show button create */
            {/*console.log('create new hidden modal without data')*/}
            x = (<div key={formName+"0ModalContainer"} id={formName+"0ModalContainer"}>
                    <Modal key={"modal"+0} content={formArray} additionalFormItems={extraFields} form={formArray}
                           forwardKey={0} keyID={0} formName={formName} formHeadline={formHeadline}
                           onChange={modalValidation} checkInformation={checkModalInformationFunction} question={question}
                    />
                    {/** create button "+ Hinzufügen" */}
                    <div key={"formElement0"} id="0" className={"formElement"} onClick={onClickForm}><FaPlus key={"formElememtPlusIcon0"} className={"formElememtPlusIcon"}/> Hinzufügen</div>
                </div>
            )
        }
    }

    return x;
}


function declareSplitType(splitType = "Equal", splitAmount = null, splitAmountType = "%",splitValue, countOfElements, id){
    //console.log(splitAmount);
    //console.log(countOfElements);

    if(splitAmount !== null){

        let returnSplitAmount;

        countOfElements = countOfElements -1;

        if(splitType === "Equal"){

            returnSplitAmount = splitAmount / countOfElements;

            returnSplitAmount = returnSplitAmount.toFixed(2)

            returnSplitAmount = returnSplitAmount +" "+ splitAmountType;


        }else if (splitValue !== "" ) {
            returnSplitAmount = splitValue +" "+ splitAmountType;
        }

        return returnSplitAmount
    }

}




/** to generate text on form button´s to open modal */
function mappedArrayFunction(data, formularName, formularKey, linkedFormValues = "") {
    //console.log("Mapping Formdata:");
    //console.log(data);
    //console.log("formularName + formularKey");
    //console.log(formularName + " " + formularKey);
    if (!data) {
        return;
    }

    for (const [key, value] of Object.entries(data)) {
        //console.log(key)
        //console.log(formularKey)

        if (key.includes(formularName + formularKey) ) {
          return getLinkedFormDisplayName(value)

        }
    }
}


function getLinkedFormDisplayName(value) {

    if(value["array"]) {
        const array = value["array"];
        //console.log(array);

        const fullname = (array["firstname"] || "") + (array["firstname"] && array["name"] ? " " : "") + (array["name"] || "");

        const description = (processDescription(array["description"]) || "") + (array["description"] && array["description2"] ? " / " : "") + (array["description2"] || "")

        return fullname ||
            array["fullname"] ||
            array["company"] ||
            description;
    }

    return "";
}


function getLinkedFormSymbol(value,formularKey) {

    if(value["array"]) {
        const array = value["array"];
        //console.log(array);
        if (array["description"] ) {
            return <BsBox key={"formElememtUserIcon"+formularKey} className={"formElememtUserIcon"}/>

        }else if (array["firstname"]|| array["name"] || array["fullname"] ) {
            return <FaUserCircle key={"formElememtUserIcon"+formularKey} className={"formElememtUserIcon"}/>

        }else if (array["company"] ) {
            return <IoBusinessOutline key={"formElememtUserIcon"+formularKey} className={"formElememtUserIcon"}/>

        }
    }

}

/** form input element description is used as multi select, then the value is stored as JSON and should be combined comma separated */
function processDescription(description) {

    let parsedDescription;
    if (typeof description === 'string') {
        try {
            parsedDescription = JSON.parse(description);
        } catch (error) {
            /** If the parsing fails, it is a normal string */
            return description;

        }
    } else {
        parsedDescription = description;
    }

    /** Check whether it is an array of objects */
    if (Array.isArray(parsedDescription) && parsedDescription.every(item => typeof item === 'object' && item !== null)) {
        const labels = parsedDescription.map(item => item.label || '').join(', ');
        return labels;
    } else {
        return null;
    }
}

function mappedArraySymbol(data = null, formularName, formularKey, linkedFormValues = "") {
    if (!data) {
        return null;
    }
    //console.log("Mapping Formdata:");
    //console.log(data);

    for (const [key, value] of Object.entries(data)) {


        if (key.includes(formularName + formularKey)) {
            console.log('mappedArraySymbol')
            console.log(key)

            const array = value["array"];

            if (array["description"] ) {
                return <BsBox key={"formElememtUserIcon"+formularKey} className={"formElememtUserIcon"}/>

            }else if (array["firstname"] || array["name"] || array["fullname"] ) {
                return <FaUserCircle key={"formElememtUserIcon"+formularKey} className={"formElememtUserIcon"}/>

            }else if (array["company"] ) {
                return <IoBusinessOutline key={"formElememtUserIcon"+formularKey} className={"formElememtUserIcon"}/>

            }
        }
    }
}





function linkedVisualElement(data = null, formularName, formularKey, linkedForm){
    let fieldName
    let displayText

    if(data !== null && linkedForm !== ""){
        for(const [key,value] of Object.entries(data)){
            if(key === formularName+formularKey){
                const array = value["array"];

                if(array["firstname"] !== undefined || array["name"] !== undefined || array["company"] !== undefined || array["fullname"] !== undefined) {

                    if (array["firstname"] || array["name"]) {
                        displayText = array["firstname"] + " " + array["name"];
                    } else if (array["fullname"]) {
                        displayText = array["fullname"];
                    } else if (array["company"]) {
                        displayText = array["company"];
                    } else {
                        displayText = null
                    }

                    fieldName = <div className={"row"}>
                        <div className={"smallContent"}>
                            <FaLevelUpAlt className={"formElememtUserIcon"} style={{transform: "rotate(90deg)"}}></FaLevelUpAlt><FaUserCircle className={"formElememtUserIcon"}/>
                        </div>
                        <div className={" textColor"}>
                            {displayText}
                        </div>
                    </div>

                }else{
                    const description = (processDescription(array["description"]) || "") + (array["description"] && array["description2"] ? " / " : "") + (array["description2"] || "")

                    fieldName = <div className={"row"}>
                        <div className={"smallContent"}>
                            <FaLevelUpAlt className={"formElememtUserIcon"} style={{transform: "rotate(90deg)"}}></FaLevelUpAlt><BsBox className={"formElememtUserIcon"}></BsBox>
                        </div>
                        <div className={" textColor"}>
                            {description}
                        </div>
                    </div>
                }

                return <div className={"formElement linkedVisual"}>
                    {fieldName}
                </div>

            }
        }

    }

}


export default Form;