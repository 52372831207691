
async function getAccessToken() {

    const apiUrl = process.env.REACT_APP_DEV_API_URL;
    const clientId = process.env.REACT_APP_DEV_API_CLIENT;
    const clientSecret = process.env.REACT_APP_DEV_API_SECRET;

    const url = `${apiUrl}/oauth/token`;

    //const credentials = btoa(`${clientId}:${clientSecret}`);
    const credentials = Buffer.from(`${clientId}:${clientSecret}`).toString('base64');

    //console.log('API CAll')
    //console.log(url)

    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Basic ${credentials}`
            },
            body: "grant_type=client_credentials"
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
        }

        const data = await response.json();
        //console.log('Response Data:', data);
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
    }


}

export default getAccessToken;