
/** appear logic vor pages */
function Steps(json,step,stepBackVal,appear = [],nextCurrentStep,currentStepName,alreadyManaged){

    let x = "";
    let state ;
    let stateStep;
    let stepTroughtAppearState = false;
    let mappedAppear = false;

    console.log("function Steps")

    /** jump directly to step */
    if (nextCurrentStep) {
        const nextPage = json.page.find(page => page.wizardPageName === nextCurrentStep);
        if (nextPage) {
            return nextPage;
        }
    }

    const map = (page, hasStep = undefined) =>{

        if(hasStep === undefined){
            console.log("hasStep === undefined")

            if(appear.length !== 0) {
                if(page.appear !== undefined){
                    if (page.appear.length !== 0 ) {
                        let alreadyMangedStateCase = true;
                        //console.log("AlreadyMangedStateSome")
                        alreadyManaged.some(elem =>{
                            if(elem.done !== page.wizardPageName){

                            }else{
                                //console.log(elem.done);
                                //console.log(page.wizardPageName);
                                alreadyMangedStateCase = false
                            }
                        });

                        if (page.wizardPageName !== currentStepName && alreadyMangedStateCase) {

                            page.appear.forEach((appearState) => {
                                let andState = 0, orState = false, notOrState = true, notAndState = true;

                                // check selectionAnd
                                if (appearState.selectionAnd && appearState.selectionAnd.length !== 0) {
                                    andState = appearState.selectionAnd.every(select =>
                                        appear.some(appearSingleKeys => select.selection === appearSingleKeys.selection));
                                } else {
                                    andState = true; // Keine and-Bedingungen, also als erfüllt behandeln
                                }


                                // check selectionOr
                                if (appearState.selectionOr && appearState.selectionOr.length !== 0) {
                                    orState = appearState.selectionOr.some(select =>
                                        appear.some(appearSingleKeys => select.selection === appearSingleKeys.selection));
                                } else {
                                    orState = true; // Keine or-Bedingungen, also als erfüllt behandeln
                                }

                                // check notOr
                                if (appearState.notOr && appearState.notOr.length !== 0) {
                                    notOrState = !appearState.notOr.some(select =>
                                        appear.some(appearSingleKeys => select.selection === appearSingleKeys.selection));
                                }

                                // check notAnd
                                if (appearState.notAnd && appearState.notAnd.length !== 0) {
                                    notAndState = appearState.notAnd.every(select =>
                                        !appear.some(appearSingleKeys => select.selection === appearSingleKeys.selection));
                                }

                                if (andState && orState && notOrState && notAndState) {
                                    stateStep = true;
                                    state = page;
                                    x = state;
                                }

                            });



                        }else{
                            if(stepBackVal === true){
                                console.log("step Back Val true")
                                return stepTroughtAppearState = true;
                            }
                        }
                    }
                    if (page.wizardPageName === step){
                        if(hasStep === undefined) {
                            stateStep = true;
                        }
                    }
                }
            }else if (page.wizardPageName === step){
                if(hasStep === undefined){
                    stateStep = true;
                }

            }


        }

    }

  //  console.log("PrioStep");
  //  priorityStep(json.page,step);

    json.page.map((page,i) => {
        map(page,stateStep);

    });


    json.page.map((page,i) => {
        if(!stepTroughtAppearState){
            console.log('Not stepTroughtAppearState')

               if(page.wizardPageId === step){
                    x = page
                    mappedAppear = true
                    return x

               }else if(page === state) {
                   x = page
                   mappedAppear = true
                   return x

               }

               return x

        }else {
               if (page.wizardPageName === step){
                   console.log('stepTroughtAppearState')
                    //console.log("StepValInit")
                    //console.log(step)
                    x = page
                    x.wizardPrevStep = step;
                    return x

               }
        }

    })


    if(!mappedAppear){
        json.page.map((page,i) => {
            console.log('not mappedAppear')

            if(page.wizardPageName === step){
                x = page
                return x;
            }
            return  x
        })
    }

    return x



}

export default Steps