import React from "react";
import "../Styles/Button.css";
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/all.js";


const Button = ({id,onClick,typedesc,control,splitForm,nextStep,prevStep,currentStep,disabled,singular = false,jump= false}) => {
    // console.log(splitForm);
    // console.log(prevStep);

    if(control === "backstep"){
        return (<div className={"alignLeft"} >
                <button  onClick={onClick} className="backColor btnSrc" id={id} data-typedesc={typedesc}
                         data-step={prevStep} data-currentstep={currentStep} data-control={control}>
                    <div className={"row"}>
                        <div className={"smallContentButtonLeft"}>
                            <IoIosArrowBack className={"vectorLeft"}/>
                        </div>
                        <div className={"largeContent95"}>
                            {typedesc}
                        </div>
                    </div>
                </button>
            </div>
        )
    }else if (control === "nextstep" || control === "safe" || control === "endsafe"){
        let className = "nextColor btnSrc btnDisabled ";

        let nextCurrentStep = jump ? nextStep : "";

        if(singular === true){
            className = className + "singular"
            return (
                <button onClick={onClick} data-splitform={splitForm} disabled={!disabled} className={className}
                        id={id} data-typedesc={typedesc} data-step={nextStep} data-currentstep={currentStep} data-control={control} data-jump={nextCurrentStep} >
                    <div className={"row"}>
                        <div className={"largeContent95"}>
                            <b >{typedesc}</b>
                        </div>
                        <div className={"smallContentButtonRight"}>
                            <IoIosArrowForward className={"vectorRight"}/>
                        </div>
                    </div>
                </button>

            )
        }else {
            return (<div className={"alignRight" } >
                    <button onClick={onClick} data-splitform={splitForm}  disabled={!disabled} className={className}
                            id={id} data-typedesc={typedesc} data-step={nextStep}  data-currentstep={currentStep} data-control={control} data-jump={nextCurrentStep}>
                        <div className={"row"}>
                            <div className={"largeContent95"}>
                                <b >{typedesc}</b>
                            </div>
                            <div className={"smallContentButtonRight"}>
                                <IoIosArrowForward className={"vectorRight"}/>
                            </div>
                        </div>
                    </button>
                </div>
            )
        }
    }else {
        return (<div></div>)
    }
}

export default Button