import Main from "./ProjektWizzard/Wizzard/main";
import { useLocation, useParams } from 'react-router-dom';
import "./ProjektWizzard/Styles/Wizzard.css";

function App () {

    const location = useLocation();
    //const { wizardId } = useParams();

    const searchParams = new URLSearchParams(location.search);
    const wizardIdQueryParam = searchParams.get('wizardId');
    const wizardId = parseInt(wizardIdQueryParam, 10) || 1;


    return(<div className={"App-header"}>
                    {Main(wizardId)}
        </div>
    )
}

export default App;

