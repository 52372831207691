import React, { useState, useEffect } from 'react';
import { parse } from 'date-fns';
import DatePicker from 'react-mobile-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import de from 'date-fns/locale/de';
import {BsCalendar2Week} from 'react-icons/bs';
import "../Styles/CustomDatePicker.css";
import { format } from 'date-fns';

const MBDatePickerComponent = ({className,name,placeholder,unique,required,onchange = () => {},checkInformation = () => {}, mappedData = null,id = null, pageId= null, choiceId= null, question = null,  innerFormChange= null}) => {

    //console.log('mappedData')
    //console.log(mappedData)

    /** incoming date value is string and must convert to date */
    const parseDateString = (dateString, format) => {
        return parse(dateString, format, new Date());
    };

    let parsedDate;

    if(mappedData !== null && mappedData !== ''){
         parsedDate = parseDateString(mappedData, "dd.MM.yyyy");

    }

    //console.log("onChange");
    //console.log(onchange);

    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

    const [selectedDate, setSelectedDate] = useState();

    useEffect(() => {
        if (parsedDate instanceof Date) {
                setSelectedDate(parsedDate);
        }
    }, [mappedData]);



    const handleCancel = () => {
        setIsDatePickerOpen(false);
    }

    const handleSelect = (date) => {
        setSelectedDate(date);
        setIsDatePickerOpen(false);

    }


    const dateConfig = {
        year: {
            format: "YYYY",
            caption: "Jahr",
            step: 1
        },
        month: {
            format: "MM",
            caption: "Mon",
            step: 1
        },
        date: {
            format: "DD",
            caption: "Day",
            step: 1
        }
    };


    const handleDateChange = (date) => {
        setSelectedDate(date);

        const simulatedEvent = {
            currentTarget: {
                value: formatDate(date),
                name: name,
                required: required,
                id: id,
                dataset: {
                    pageid: pageId,
                    choiceid: choiceId,
                    question: question
                },
                classList: {
                    contains: (className) => className === 'input',
                    add: () => {},
                    remove: () => {},
                    toggle: () => {}
                }
            },
            target: {
                value: formatDate(date),
                name: name,
                required: required,
                id: id,
                dataset: {
                    pageid: pageId,
                    choiceid: choiceId,
                    question: question
                },
                classList: {
                    contains: (className) => className === 'input',
                    add: () => {},
                    remove: () => {},
                    toggle: () => {}
                }
            }
        };

        onchange(simulatedEvent);
        //checkInformation(simulatedEvent)

        if (typeof innerFormChange === 'function') {
             innerFormChange(simulatedEvent);
        }
    };

    const handleDatePickerClick = () => {
        setIsDatePickerOpen(true);
    };

    const handleDatePickerBlur = () => {
        setIsDatePickerOpen(false);
    };

    // Warning: Function components cannot be given refs. Attempts to access this ref will fail. Did you mean to use React.forwardRef()?
    // Da CustomInput ein Funktionskomponente ist, kann es keine ref von einem Elternteil empfangen, es sei denn, es ist speziell dafür eingerichtet, was mit React.forwardRef gemacht wird.
    // Stellen Sie sicher, dass Sie auch die anderen Props an CustomInput durchreichen, die Sie vorher direkt an das Input-Element gegeben haben, wie id, key, name, required, className,

    const CustomInput = React.forwardRef(({ onClick, value, elementName, ...rest }, ref) => {
        const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

        return (
            <div className="custom-input">
                <input
                    type="text"
                    name={name}
                    onClick={handleDatePickerClick}
                    onChange={handleDateChange}
                    id={id}
                    key={unique}
                    required={required}
                    className={className}
                    value={formatDate(selectedDate)}
                    readOnly={isMobile}
                    placeholder={placeholder}
                    ref={ref} // forwarding ref
                    {...rest} // include all other probs
                />
                <BsCalendar2Week className="calendar-icon" onClick={onClick}/>
            </div>
        );
    });

    const formatDate = (date) => {
        if (date && date instanceof Date) {
            return date ? format(date, 'dd.MM.yyyy') : '';
        }
        return '';
    };

    return (
        <div>

            <div >
                <CustomInput id={'CustomInput'+unique}
                             required={required}
                             className={className}
                             onClick={handleDatePickerClick}
                             onBlur={handleDatePickerBlur}
                />
                <DatePicker
                    value={selectedDate}
                    isOpen={isDatePickerOpen}
                    isPopup={true}
                    onChange={handleDateChange}
                    onSelect={handleSelect}
                    onCancel={handleCancel}
                    theme="ios"
                    showHeader={false}
                    max={new Date()}
                    dateConfig={dateConfig}
                    confirmText={"auswählen"}
                    cancelText={"abbrechen"}
                />
            </div>

        </div>
    );
};

export default MBDatePickerComponent;
