import ProgressBar from './ProgressBar.js';

import "../Styles/SpliboxStyles.css";
import {FaLock} from "react-icons/all";
import {useRef, useState} from "react";

const SplitAmountBox = ({data,linkedFormName,onchangeSplitAmount,splitArray,splitOpt,question= null}) => {


    /*let filteredDataKeys = Object.keys(data).filter(key => key.startsWith(linkedFormName));
    let filteredData = filteredDataKeys.reduce((acc, key) => {
        acc[key] = data[key];
        return acc;
    }, {});*/

    /** filter data to linked form from formData array */
    let linkedFormNames = linkedFormName.split(',');
    let linkedFormNamesSet = new Set(linkedFormNames);

    let filteredData = Object.keys(data).reduce((acc, key) => {
        for (let formName of linkedFormNamesSet) {
            if (key.startsWith(formName)) {
                acc[key] = data[key];
                break;
            }
        }
        return acc;
    }, {});


    /** init split box with data in splitArray */
    let initialCounters = {};
    Object.keys(filteredData).forEach(key => {
        //console.log("init split box");
        //console.log(key);
        //console.log(splitArray);
        initialCounters[key] = (splitArray && splitArray[key]) ? splitArray[key] : 0;

     });




    //console.log('splitOpt')
    //console.log(splitOpt.maxAmountType)
    //console.log(splitOpt.maxAmount)

    const [counters, setCounters] = useState(initialCounters);
    const [restCounter, setRestCounter] = useState(splitOpt.maxAmount);

    const intervalRef = useRef(null);

    const startCounter = (e) => {
        if (intervalRef.current) return;

        let currentTargetId = e.currentTarget.id;
        let boxId = e.currentTarget.closest(".contentSplitBox").id;
        let prevCounter;

        intervalRef.current = setInterval(() => {
            const nextCounters = Object.keys(counters).reduce((acc, key) => {
                if (key === boxId) {
                    if (currentTargetId === "minus") {
                        /** click "minus" */
                        if(prevCounter <= 0) {
                            stopCounter();
                            return;
                        }

                        if (counters[key] > 0 ) {
                            if(counters[key]-1 === prevCounter || prevCounter){
                                prevCounter = prevCounter-1;
                                acc[key] = prevCounter;

                            } else {
                                prevCounter = counters[key]-1;
                                acc[key] = counters[key] - 1;


                            }
                        } else {
                            acc[key] = counters[key];
                        }

                    } else {
                        /** click "plus" */
                        if(counters[key]+1 === prevCounter || prevCounter){
                            prevCounter = prevCounter+1;
                            acc[key] = prevCounter;
                        } else {
                            prevCounter = counters[key]+1;
                            acc[key] = counters[key] + 1;
                        }

                    }
                } else {
                    /** The rest haven't changed */
                    acc[key] = counters[key];
                }
                return acc;
            }, {});

            /** Stops the counter when the next sum is above the maximum */
            const nextSum = sumCounters(nextCounters);

            if (nextSum > splitOpt.maxAmount ) {
                stopCounter();
                return;
            }
            setRestCounter(splitOpt.maxAmount - nextSum)
            onchangeSplitAmount(nextCounters,question);
            setCounters(nextCounters);
        }, 50);
    };

    const sumCounters = (obj) => Object.values(obj).reduce((acc, val) => acc + val, 0);

    const lockButtonsForId = (e) => {

        let Element = e.currentTarget
        let id = Element.id

        let Buttons = document.querySelectorAll(`[data-lockindex="${id}"]`);

        if(Element.classList.contains("locked")){
            Element.classList.remove("locked");
            Element.classList.add("notLocked");
            Buttons.forEach((elem,ind) =>{
                if(elem.hasAttribute("disabled")){
                    elem.removeAttribute("disabled");
                }
            })
        }else{
            Element.classList.remove("notLocked");
            Element.classList.add("locked");
            Buttons.forEach((elem,ind) =>{
                if(!elem.hasAttribute("disabled")) {
                    elem.setAttribute("disabled", "true");
                }
            })

            /** Calculate the total amount already allocated */
            const totalAllocated = Object.values(counters).reduce((sum, counter) => sum + counter, 0);

            /** Calculate the remaining amount */
            const remainingAmount = splitOpt.maxAmount - totalAllocated;



            /** Find the next unlocked element */
            const firstUnlockedKey = Object.keys(counters).find(key => !document.getElementById("lock"+key).classList.contains("locked") && key !== id);
            //console.log(firstUnlockedKey)

            if (firstUnlockedKey) {
                /** Update the counters to allocate the remaining amount to the next unlocked element */
                const updatedCounters = {
                    ...counters,
                    [firstUnlockedKey]: counters[firstUnlockedKey] + remainingAmount
                };

                setCounters(updatedCounters);
                onchangeSplitAmount(updatedCounters,question);
                setRestCounter(0)
            }

        }



    }

    const stopCounter = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    };

    return <div className={"SplitBox"}>
        <div className={"textSize"}>
            <div className={"row"} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {restCounter > 0 ?
                    <div >-&nbsp;Zur Verteilung übrig&nbsp;&nbsp;<span className={"mainYellow"}>{restCounter} {splitOpt.maxAmountType}</span>&nbsp;-</div> :
                    <div >-&nbsp;Verteilung vollständig&nbsp;-</div>
                }
            </div>
        </div>
        {Object.entries(filteredData).map(([key, value], index) => {
            let marginTopValue = 0;
            if (index >= 0) {
                marginTopValue = 15;
            }
            /** access to props of 'value.array' Objekt */
            const { firstname, name, company ,fullname} = value.array;

            index = key;

            return (<div key={index} className={"contentSplitBox"} id={index} style={{marginTop:marginTopValue+"px"}}>
                <div className={"SplitBoxFlexBox"}>
                    <div className={"percentageContent"}>
                        <div className={"percentageHeader"}>
                            <div className={"halfContent"} style={{color:"#DEE9ED"}}>{firstname} {name} {company} {fullname}</div>
                            <div className={"halfContent SplitBoxIconStyle"} style={{}}><FaLock id={"lock"+index} className="notLocked" onClick={lockButtonsForId}></FaLock></div>
                        </div>
                        <div style={{marginTop:"18px"}}>
                            <ProgressBar bgcolor={"#F0BC36"} completed={counters[index]} />
                        </div>
                    </div>
                    <div className={"percentageObject"}>
                        <div className={"rowContentPercentage"}>
                            <div className={"inputField"}> <button onMouseDown={startCounter} onTouchStart={startCounter} onMouseUp={stopCounter} onTouchEnd={stopCounter} onMouseLeave={stopCounter} id={"minus"} data-lockindex={index} className={"buttonStyleProgress"}>-</button>
                                <p className={"SplitBoxText"} data-formindex={key}>{counters[index]}{splitOpt.maxAmountType}</p>
                                <button id={"add"} onMouseDown={startCounter} onTouchStart={startCounter} onMouseUp={stopCounter} onMouseLeave={stopCounter} onTouchEnd={stopCounter} data-lockindex={index}  className={"buttonStyleProgress"}>+</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)
        })}
    </div>

}

export default SplitAmountBox