import getAccessToken from '../api/getAccessToken';


async function getWizardById(wizardId) {

    const apiUrl = process.env.REACT_APP_DEV_API_URL;
    const url = `${apiUrl}/wizard/getWizard/${wizardId}`;

    //console.log('API CAll')
    //console.log(url)

    try {

        const tokenData = await getAccessToken();
        if (!tokenData || !tokenData.access_token) {
            throw new Error("Failed to retrieve access token");
        }

        const token = tokenData.access_token;

        const response = await fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
        }

        const data = await response.json();
        //console.log('Response Data:', data);
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
    }

}

export default getWizardById;