import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import '../Styles/Wizzard.css'
import Body from "../Class/Body";
import getWizardById from '../api/getWizardById';
import {forEach} from "react-bootstrap/ElementChildren";

function Main(wizardId = 1) {
    const [uniqueId, setUniqueId] = useState('');
    const [wizard, setWizard] = useState(null);

    useEffect(() => {
        setUniqueId(uuidv4()); // Generiert bei der ersten Ausführung eine eindeutige ID

        async function fetchWizard() {
            const data = await getWizardById(wizardId);
            setWizard(data);
        }

        fetchWizard();
    }, [wizardId]);

    //console.log('Wizard Data:', wizard);

    if (!wizard) {
        return <div>Loading...</div>;
    }else{


        const colorSettings = wizard.color;

        if(colorSettings && Object.keys(colorSettings).length > 0) {
            function changeColors(varName, colorValue) {
                document.documentElement.style.setProperty(`--${varName}`, colorValue);
            }

            Object.keys(colorSettings).forEach((varName) => {
                const colorValue = colorSettings[varName];
                changeColors(varName, colorValue);
            });
        }


    }

    //console.log("Eindeutige ID: ", uniqueId);

    return <Body title={wizard?.wizardName} objects={wizard} userId={uniqueId} wizardId={wizardId}/>;
}


export default Main;
