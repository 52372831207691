import React from "react";
import {FaInfoCircle} from "react-icons/fa";
import {BsInfoCircle} from 'react-icons/bs';
import {IoIosArrowForward} from "react-icons/all.js";

// import Linkify from 'react-linkify'; this function linkify is
// a possibility to dynamic render links in text. But we don't use it.
// we have popoverlink in json model but actually don't use it.
// we created parseAndRenderLinks function by self. Check if is vulnerable bei cross site attack

const InfoModal = ({ModalText,id,popoverLink}) => {
    //console.log(popoverLink)
    try {
        return <div className="infoModal" id={id}>
            <div className="infoModal_Content">
                <div style={{marginTop: "25px"}}>
                </div>
                <div className={"infoModal_innerConntent "}>
                    <div style={{marginTop: "10px"}}></div>
                    <div className={"closeButtonInfoModal"}>
                        <div className={"row"}>
                            <div className={"smallContent"}>
                                <BsInfoCircle className={"vectorInfoModal"}></BsInfoCircle>
                            </div>
                            <div className={"largeContent95"} style={{ whiteSpace: 'pre-line' }}>
                                {parseAndRenderLinks(ModalText)}
                            </div>
                        </div>
                    </div>
                    <div className={"closeButtonInfoModal"}>
                        <button className={"infoModalDoneButton "}>
                            <div className={"row"}>
                                <div className={"largeContent95"}>
                                    <b>Schließen</b>
                                </div>
                                <div className={"smallContentButtonRight"}>
                                    <IoIosArrowForward className={"vectorRight"}/>
                                </div>
                            </div>
                        </button>
                        <div style={{marginTop: "10px"}}></div>
                    </div>
                </div>
            </div>
        </div>
    }catch (e) {
       // console.log("ModalError :"+e)
    }
}


function parseAndRenderLinks(text) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, 'text/html');
    const links = doc.querySelectorAll('a');

    /** split the text at the links found */
    let parts = [text];
    links.forEach(link => {
        const linkHTML = link.outerHTML;
        parts = parts.flatMap(part => part.split(linkHTML));
    });

    /** create an array with text and link blocks */
    const result = [];
    links.forEach((link, index) => {
        result.push(parts.shift());
        result.push(<a key={index} href={link.href} target="_blank" rel="noopener noreferrer">{link.textContent}</a>);
    });

    result.push(...parts);

    return result;
}


export default InfoModal;