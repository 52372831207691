import React, { useState, useEffect } from "react";
import Signature from "./Signature"
import Upload from "./Upload"
import "../Styles/Input.css"
import "../Styles/Button.css";
import "../Styles/payment.css"
import SplitAmountBox from "./SplitAmountBox";
import DatePickerComponent from "./DatePicker.js";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import Checkout from './Checkout';
import MBDatePickerComponent from "./DatePickerMB.js";

const Inputs = ({pageId,id,types,selectOpt,onchange,disabled,selected,required,choiceId,placeHolder,name,forwardKey,
                    answers,cssClass,formArray,linkedFormNameInput,onchangeSplitAmountConst,linkedInputArray,
                    splitAmountArray,splitOpt,userId,value,handlePaymentSuccess,payment,question,icon}) => {

    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    const [paymentData, setPaymentData] = useState(null);
    const [payerData, setPayerData] = useState(null);
    const [revocation, setRevocation] = useState();
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [showPayPalSection, setShowPayPalSection] = useState(false);
    const [showInfoSection, setShowInfoSection] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState('Bezahlung erforderlich');
    const [paymentInfo, setPaymentInfo] = useState('Deine Bestellung');
    const [paymentRequired, setPaymentRequired] = useState("");

    let defindedValue = undefined
    let splitArray = undefined
    let checkDisabled

    const unique = pageId+':'+id

    const initialOptions = {
        "client-id": process.env.REACT_APP_DEV_PAYPAL_CLIENT,
        "enable-funding": "paylater,venmo,card",
        "disable-funding": "",
        "data-sdk-integration-source": "integrationbuilder_sc",
        "currency": "EUR",
        "intent": "capture",
    };

    useEffect(() => {
        if(types === "payment") {
            try {
                let parsedPaymentData = JSON.parse(value.replace(/'/g, "\""));

                let shipping = parseFloat(parsedPaymentData.shipping) || 0;
                let vat = parseFloat(parsedPaymentData.vat) || 0;
                let parsedValue = parseFloat(parsedPaymentData.value) || 0;

                let crossAmount = shipping + parsedValue;
                let vatAmount = crossAmount * vat / 100;
                let totalAmount = crossAmount + vatAmount;

                if (parsedPaymentData.currency && !isNaN(parsedValue) && parsedPaymentData.description) {
                    setPaymentData({
                        currency: parsedPaymentData.currency,
                        value: parsedValue,
                        shipping: shipping,
                        cross: crossAmount,
                        vat: vat,
                        vatAmount: vatAmount,
                        totalAmount: totalAmount,
                        description: parsedPaymentData.description
                    });
                }

            } catch (error) {
                //console.error("Fehler beim Parsen der payment Informationen:", error);
            }

            try {
                let payerData = {};
                let keys = Object.keys(answers);

                if(!linkedInputArray) {
                    linkedInputArray = [];
                }

                linkedInputArray.forEach(inputName => {
                    for(let i = 0; i < keys.length; i++) {
                        if(answers[keys[i]]["nameInput"] === inputName) {
                            payerData[inputName] = answers[keys[i]]["answer"];
                            break;
                        }
                    }
                });

                setPayerData(payerData)

            } catch (error) {
                console.error("Fehler beim Parsen payer Informationen", error);
            }

        }
    }, [types, value]);

    useEffect(() => {
        if (payment && payment.status === "COMPLETED") {
            setShowPayPalSection(false);
            setPaymentStatus('Bezahlung erfolgreich durchgeführt');
            setPaymentInfo('Bezahlung Erfolgreich');
            setShowInfoSection(true);
            setPaymentRequired("COMPLETED");

            programmaticallyTriggerChange();
            //console.log('Bezahlung Erfolgreich');
            //console.log(payment);

        }
    }, [payment]);

    const handleButtonClick = () => {
        setShowPayPalSection(true); // PayPal-Abschnitt anzeigen
    };

    const onPaymentChange = (isChecked) => {
        setRevocation(isChecked);

        if (isChecked) {
            setButtonDisabled(false);
            setPaymentInfo('Bezahlung durchführen');

        } else {
            setButtonDisabled(true);
            setShowPayPalSection(false);
            setPaymentInfo('Bezahlung erforderlich');
        }
    }

    const programmaticallyTriggerChange = () => {
        const simulatedEvent = {
           currentTarget: {
                value: 'COMPLETED',
                name: name,
                required: required,
                id: id,
                dataset: {
                    pageid: pageId,
                    choiceid: choiceId,
                    question: question,
                },
                classList: {
                    contains: (className) => className === 'input',
                    add: () => {},
                    remove: () => {},
                    toggle: () => {}
                }
            }
        };
        onchange(simulatedEvent);

    };

    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleString('de-DE', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    });

    if(required === "true"){
        required = true
    }else if(required === "false"){
        required = false
    }else{
        required = false
    }

    /** if is set splitAmountArray get current by pageId */
    if(splitAmountArray !== undefined && splitAmountArray !== null){
        //console.log('splitArray')
        //console.log(linkedFormNameInput)

        let currentSplitAmountArray = splitAmountArray.find(obj => obj.linkedForm === linkedFormNameInput);
        splitArray = currentSplitAmountArray && currentSplitAmountArray.splitArray;

        //console.log(splitArray)
    }

    if(disabled !== undefined && disabled === "true"){
        checkDisabled = true;
    }else{
        checkDisabled = false
    }

    const selectBoxesRadio = (selectOpt) => {
        let stateKey = pageId+":"+id;
        let keys = Object.keys(answers);
        let indexKeys = 0;
            for(indexKeys;indexKeys<keys.length;indexKeys++)
            {
                selectOpt.map((option,i) => {
                    if(keys[indexKeys].includes(stateKey,0)){

                        let indexOf = keys[indexKeys].indexOf(stateKey)
                        if(indexOf === 0){

                            let state = pageId+":"+id+":"+option.choiceId;

                            if(keys[indexKeys] === state){
                                normalMapping(state,i);
                            }
                        }
                    }
                })
            }


    }

    const normalMapping = (state, selectedBoxId) => {
        let keys = Object.keys(answers);
        let indexKeys = 0;

        if(linkedInputArray === undefined){
            linkedInputArray = [];
        }
        //console.log(name);

        for(indexKeys;indexKeys<keys.length;indexKeys++) {

            if (keys[indexKeys].includes(state, 0)) {
                let indexOf = keys[indexKeys].indexOf(state)

                if (indexOf === 0) {

                    if (keys[indexKeys] === state) {

                        if(types === "checkbox" || types === "radioSelect"){
                            return defindedValue = selectedBoxId;

                        }else{
                            return defindedValue = answers[keys[indexKeys]]["answer"];
                        }

                    }
                }
            }else if(linkedInputArray.includes(name)){

                if(name === answers[keys[indexKeys]]["nameInput"]){
                    return defindedValue = answers[keys[indexKeys]]["answer"]
                }


            }
        }
    }

    if(types !== "radioSelect" ) {
        let state = pageId + ":" + id + ":" + choiceId;
        normalMapping(state);
    }else{
        selectBoxesRadio(selectOpt);
    }

    if(types === "textbox" || types === "text"){

        return (
            <input data-pageid={pageId} data-question={question} required={required} onChange={onchange} id={id} name={name} key={forwardKey} disabled={checkDisabled} data-choiceid={choiceId} className={"input " + cssClass} placeholder={placeHolder} value={defindedValue}/>
        )

    }else if (types === "select") {
        return <select id={id} required={required}  onChange={onchange} className={"select"} name={name}>
            <option>Bitte wählen</option>
            {selectOpt.map((option,i) => {
                if(selected.some(select => select.selection === (pageId+":"+id+":"+option.choiceId))){
                    return <option data-pageid={pageId} data-question={question} data-questionid={id}  selected={true} data-choiceid={option.choiceId} data-choicevalue={option.choiceValue}>{option.choiceText}</option>
                }
                return <option data-pageid={pageId} data-question={question} data-questionid={id}  data-choiceid={option.choiceId} data-choicevalue={option.choiceValue}>{option.choiceText}</option>
            })}
            </select>

    }else if(types === "signature"){
        return <Signature pageId={pageId} id={unique} name={name} keyId={unique} choiceID={choiceId}/>

    }else if(types === "upload"){
        return <Upload />

    }else if(types === "textarea"){
        return <textarea data-pageid={pageId} data-question={question} required={required} onChange={onchange} id={id} name={name} key={forwardKey} data-choiceid={choiceId} className={"input " + cssClass} placeholder={placeHolder} value={defindedValue}/>

    }else if(types === "checkBox"){
        if(defindedValue !== undefined) {
            return <input onChange={onchange} required={required} type="checkbox" className={"checkBox"} name={name}
                          data-pageid={pageId} data-question={question} id={id} data-choiceid={choiceId} data-choicevalue={""} checked={true}/>
        }else{
            return <input onChange={onchange} required={required} type="checkbox" className={"checkBox"} name={name}
                   data-pageid={pageId} data-question={question} id={id} data-choiceid={choiceId} data-choicevalue={""}/>
        }

    }else if(types === "radioButton"){
        /** @todo insert input radio option here  */

    }else if(types === "date" && !isMobile){

        let className = "input";
        const placeholder = placeHolder;
        const mappedData = defindedValue;
        return DatePickerComponent({className,name,placeholder,unique,required,onchange,mappedData,id,pageId,choiceId,question})

    }else if(types === "date" && isMobile){

        let className = "input";
        const placeholder = placeHolder;
        const mappedData = defindedValue;
        return MBDatePickerComponent({className,name,placeholder,unique,required,onchange,mappedData,id,pageId,choiceId,question})

    }else if(types === "splitBox"){
       return <SplitAmountBox data={formArray} linkedFormName={linkedFormNameInput} onchangeSplitAmount={onchangeSplitAmountConst} splitArray={splitArray} splitOpt={splitOpt} question={question}/>

    }else if(types === "radioSelect"){
       return (<div>
                    {selectOpt.map((option,i) => {
                       // console.log("defined: ")
                       // console.log(defindedValue)
                       // console.log("keyOption")
                       // console.log(i)
                        if(defindedValue !== undefined && defindedValue === i){
                            return (<label key={"label"+i}>
                                <div key={"radioSelectLabel"+i} className={"radioSelectLabel"}>
                                    <input required={required} data-pageid={pageId} data-questionid={id} data-question={question}
                                           onChange={onchange} id={id} name={name} key={forwardKey} data-choiceid={option.choiceId}
                                           className={"radioSelect"} type={"radio"} checked={true} defaultValue={option["choiceText"]}/>
                                    <span key={"choiceText"+i} className={"choiceText"} >{option["choiceText"]}</span>
                                </div>
                            </label>)

                        }else{
                            return (<label key={"label"+i}>
                                <div key={"radioSelectLabel"+i} className={"radioSelectLabel"}>
                                    <input required={required} data-pageid={pageId} data-questionid={id} data-question={question}
                                           onChange={onchange} id={id} name={name} key={forwardKey} data-choiceid={option.choiceId}
                                           className={"radioSelect"} type={"radio"} defaultValue={option["choiceText"]}/>
                                    <span key={"choiceText"+i} className={"choiceText"} >{option["choiceText"]}</span>
                                </div>
                            </label>)
                        }

                    })}
                </div>)
    }else if(types === "none"){
        if (required) {
            /** hidden input field with required option for stopping wizzard */
            return <div><input type={"hidden"} data-pageid={pageId} required={required} id={id} name={"stop"} key={forwardKey} className={"checkPayment"} /></div>
        }else {
            return <div></div>
        }


    }else if(types === "payment"){

        if(paymentData) {
            return (
                <div style={{ paddingTop: '50px' }}>

                    <input type={"hidden"} data-pageid={pageId} required={required} id={id} name={"checkPayment"} key={forwardKey} className={"checkPayment"} value={paymentRequired}/>

                    <div>
                        <div className="gwtdTi rmAhv">
                                <span>{paymentInfo}</span>
                        </div>
                        <div className="fMtbac">
                            <svg width="149" height="41" viewBox="0 0 124 34" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M46.21 7.249h-6.838a.95.95 0 00-.94.802l-2.765 17.537a.57.57 0 00.564.658h3.265a.95.95 0 00.939-.803l.746-4.73a.95.95 0 01.938-.803h2.165c4.505 0 7.105-2.18 7.784-6.5.306-1.89.013-3.375-.872-4.415-.972-1.142-2.696-1.746-4.985-1.746zm.79 6.405c-.374 2.454-2.25 2.454-4.062 2.454h-1.032l.724-4.583a.57.57 0 01.563-.481h.473c1.235 0 2.4 0 3.002.704.359.42.469 1.044.332 1.906zM66.654 13.575h-3.275a.57.57 0 00-.563.481l-.145.916-.229-.332c-.709-1.029-2.29-1.373-3.868-1.373-3.619 0-6.71 2.741-7.312 6.586-.313 1.918.132 3.752 1.22 5.031.998 1.176 2.426 1.666 4.125 1.666 2.916 0 4.533-1.875 4.533-1.875l-.146.91a.57.57 0 00.562.66h2.95a.95.95 0 00.939-.803l1.77-11.209a.568.568 0 00-.561-.658zm-4.565 6.374c-.316 1.871-1.801 3.127-3.695 3.127-.951 0-1.711-.305-2.199-.883-.484-.574-.668-1.391-.514-2.301.295-1.855 1.805-3.152 3.67-3.152.93 0 1.686.309 2.184.892.499.589.697 1.411.554 2.317zM84.096 13.575h-3.291a.954.954 0 00-.787.417l-4.539 6.686-1.924-6.425a.953.953 0 00-.912-.678h-3.234a.57.57 0 00-.541.754l3.625 10.638-3.408 4.811a.57.57 0 00.465.9h3.287a.949.949 0 00.781-.408l10.946-15.8a.57.57 0 00-.468-.895z"
                                    fill="#253B80"></path>
                                <path
                                    d="M94.992 7.249h-6.84a.95.95 0 00-.938.802l-2.766 17.537a.569.569 0 00.562.658h3.51a.665.665 0 00.656-.562l.785-4.971a.95.95 0 01.938-.803h2.164c4.506 0 7.105-2.18 7.785-6.5.307-1.89.012-3.375-.873-4.415-.97-1.142-2.694-1.746-4.983-1.746zm.79 6.405c-.374 2.454-2.249 2.454-4.063 2.454h-1.03l.724-4.583a.568.568 0 01.562-.481h.473c1.234 0 2.4 0 3.002.704.36.42.468 1.044.331 1.906zM115.434 13.575h-3.273a.567.567 0 00-.562.481l-.145.916-.23-.332c-.709-1.029-2.289-1.373-3.867-1.373-3.619 0-6.709 2.741-7.311 6.586-.312 1.918.131 3.752 1.219 5.031 1 1.176 2.426 1.666 4.125 1.666 2.916 0 4.533-1.875 4.533-1.875l-.146.91a.57.57 0 00.564.66h2.949a.95.95 0 00.938-.803l1.771-11.209a.571.571 0 00-.565-.658zm-4.565 6.374c-.314 1.871-1.801 3.127-3.695 3.127-.949 0-1.711-.305-2.199-.883-.484-.574-.666-1.391-.514-2.301.297-1.855 1.805-3.152 3.67-3.152.93 0 1.686.309 2.184.892.501.589.699 1.411.554 2.317zM119.295 7.73l-2.807 17.858a.569.569 0 00.562.658h2.822c.469 0 .867-.34.939-.803l2.768-17.536a.57.57 0 00-.562-.659h-3.16a.571.571 0 00-.562.482z"
                                    fill="#179BD7"></path>
                                <path
                                    d="M7.266 29.654l.523-3.322-1.165-.027H1.061L4.927 1.792a.316.316 0 01.314-.268h9.38c3.114 0 5.263.648 6.385 1.927.526.6.861 1.227 1.023 1.917.17.724.173 1.589.007 2.644l-.012.077v.676l.526.298a3.69 3.69 0 011.065.812c.45.513.741 1.165.864 1.938.127.795.085 1.741-.123 2.812-.24 1.232-.628 2.305-1.152 3.183a6.547 6.547 0 01-1.825 2c-.696.494-1.523.869-2.458 1.109-.906.236-1.939.355-3.072.355h-.73c-.522 0-1.029.188-1.427.525a2.21 2.21 0 00-.744 1.328l-.055.299-.924 5.855-.042.215c-.011.068-.03.102-.058.125a.155.155 0 01-.096.035H7.266z"
                                    fill="#253B80"></path>
                                <path
                                    d="M23.048 8.167c-.028.179-.06.362-.096.55-1.237 6.351-5.469 8.545-10.874 8.545H9.326c-.66 0-1.218.48-1.32 1.132l-1.41 8.936-.399 2.533a.704.704 0 00.695.814h4.881c.578 0 1.07-.42 1.16-.99l.048-.248.92-5.832.058-.32c.09-.572.582-.992 1.16-.992h.73c4.73 0 8.431-1.92 9.513-7.476.452-2.321.218-4.259-.978-5.622a4.667 4.667 0 00-1.336-1.03z"
                                    fill="#179BD7"></path>
                                <path
                                    d="M21.754 7.651a9.757 9.757 0 00-1.203-.267 15.284 15.284 0 00-2.426-.177h-7.352a1.172 1.172 0 00-1.159.992L8.05 18.105l-.045.289a1.336 1.336 0 011.32-1.132h2.753c5.405 0 9.637-2.195 10.874-8.545.037-.188.068-.371.096-.55a6.594 6.594 0 00-1.017-.429 9.045 9.045 0 00-.277-.087z"
                                    fill="#222D65"></path>
                                <path
                                    d="M9.614 8.199a1.169 1.169 0 011.159-.991h7.352c.871 0 1.684.057 2.426.177a9.757 9.757 0 011.481.353c.365.121.704.264 1.017.429.368-2.347-.003-3.945-1.272-5.392C20.378 1.182 17.853.5 14.622.5h-9.38c-.66 0-1.223.48-1.325 1.133L.01 26.398a.806.806 0 00.795.932h5.791l1.454-9.225 1.564-9.906z"
                                    fill="#253B80"></path>
                            </svg>
                            <div
                                className=" bSMhBC">
                                <span>Du kannst Deine Bestellung mit PayPal, einer Sofortüberweisung, oder Kreditkarte bezahlen.</span>
                            </div>
                        </div>
                        <div className="djiQjh">
                            <div className="etUxOQ"><span
                                className="cZqjSD">Status</span><span
                                className="ecFwR">{paymentStatus}</span>
                            </div>
                            <div className="etUxOQ"><span
                                className="cZqjSD">{paymentData.description}</span><span
                                className="ecFwR">{paymentData.value.toFixed(2)}&nbsp;€</span>
                            </div>
                            <div className="etUxOQ"><span
                                className="cZqjSD">Versandkosten</span><span
                                className="ecFwR">{paymentData.shipping ? paymentData.shipping.toFixed(2) : '0.00'}&nbsp;€</span>
                            </div>
                            <div className="etUxOQ"><span
                                className="cZqjSD">MwSt.&nbsp;{paymentData.vat.toFixed(2)}&nbsp;%</span><span
                                className="ecFwR">{paymentData.vatAmount ? paymentData.vatAmount.toFixed(2) : '0.00'}&nbsp;€</span>
                            </div>
                            <div className="etUxOQ"><span
                                className="cZqjSD">Gesamtsumme</span><span
                                className="ecFwR">{paymentData.totalAmount.toFixed(2)}&nbsp;€</span>
                            </div>
                        </div>
                        {!showPayPalSection && !showInfoSection && (
                            <div >
                                <label id={""} key={""} className={"checkBoxLabel"}>
                                    <input onChange={() => onPaymentChange(!revocation)} type="checkbox" className={"checkBox"}
                                           data-pageid={pageId} id={id} data-choiceid={""} data-choicevalue={""} checked={revocation} />
                                    <span className="checkmark"></span>
                                    <i key={"iCheckBox"} className={"checkBoxLabelInput"}>{"Mit deiner Bestellung erklärst du dich mit unseren Widerrufsbestimmungen einverstanden."}</i>

                                </label>
                            </div>)}
                        {!showPayPalSection && !showInfoSection && (
                        <div>
                            <button onClick={handleButtonClick} disabled={buttonDisabled} className={`nextColor btnSrc ${buttonDisabled ? 'btnDisabled' : ''} singular`}
                                    id={id} >
                                <div className={"row"}>
                                    <div className={"largeContent95"}>
                                        <b >Bezahlung durchführen</b>
                                    </div>
                                    <div className={"smallContentButtonRight"}>

                                    </div>
                                </div>
                            </button>
                        </div>)}
                    </div>
                    {showPayPalSection && !showInfoSection  && (
                        <PayPalScriptProvider options={initialOptions}>
                        {paymentData && <Checkout
                            paymentData={paymentData}
                            userId={userId}
                            payerData={payerData}
                            onPaymentSuccess={handlePaymentSuccess} />}
                    </PayPalScriptProvider>
                    )}
                    {showInfoSection  && (
                        <div>
                            <div className="gwtdTi rmAhv">
                            <span>Allgemeine Bestellinformationen</span>
                            </div>
                            <div className="djiQjh">
                                <div className="etUxOQ"><span
                                className="cZqjSD">ID</span><span
                                className="ecFwR">{userId}</span>
                                </div>
                                <div className="etUxOQ"><span
                                className="cZqjSD">Datum</span><span
                                className="ecFwR">{formattedDate}</span>
                                </div>
                            </div>
                        </div>
                    )}
                    {showInfoSection && (
                        <div>
                            <div className="gwtdTi rmAhv">
                                <span>Kundeninformation</span>
                            </div>
                            <div className="djiQjh">
                                <div className="etUxOQ"><span
                                    className="cZqjSD">Vollständiger Name</span><span
                                    className="ecFwR">{payment.payer.given_name || ""}&nbsp;{payment.payer.surname || ""}</span>
                                </div>
                                <div className="etUxOQ"><span
                                    className="cZqjSD">E-mail Adresse</span><span
                                    className="ecFwR">{payment.payer.email_address || ""}</span>
                                </div>
                                <div className="etUxOQ"><span
                                className="cZqjSD">Telefon</span><span
                                className="ecFwR"></span>
                                </div>
                                <div className="etUxOQ"><span
                                    className="cZqjSD">Anschrift</span><span
                                    className="ecFwR"></span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            );
        }else{
            return (
                <div>
                    <input type={"hidden"} data-pageid={pageId} required={required} id={id} name={"checkPayment"} key={forwardKey} className={"checkPayment"} value={paymentRequired}/>
                </div>
            );
        }
    }
}

export default Inputs;